import React, { useState, useEffect, useRef } from "react";

import { Table } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiService from "../../../services/ApiService";
import { Link, useNavigate } from "react-router-dom";
import Paginator from "../../../components/Paginator";
import { momentWithTZ } from "../../../utils/helper";

const AuctionsList = () => {
  const { t } = useTranslation();
  const [properties, setProperties] = useState([]);
  const [pagination, setPagination] = useState({});
  const filters = useRef({ page: 1 });

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [apiData, setApiData] = useState({
    status: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getAuctions();
  }, [apiData]);

  const getAuctions = async () => {
    try {
      setIsLoader(true);
      const response = await ApiService.request({
        method: "GET",
        url: `my-auctions`, // Replace with your API endpoint
        params: apiData,
      });
      const data = response.data;
      if (data.status) {
        setProperties(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };

  const handlePageChange = (page) => {
    setApiData((ps) => ({ ...ps, page }));
  };

  const selectAuctionStatus = (e) => {
    setApiData((ps) => ({ ...ps, status: e.target.value }));
  };

  return (
    <div>
      <div className="real-estate-dashboard">
        <div className="d-flex justify-content-between align-items-center rounded estate-search-bar mt-5">
          <h3 className="p-3">{t("auctions")}</h3>
        </div>
      </div>

      <div className="table-estate p-3 mt-3 rounded bg-white">
        <select
          name="type"
          className="form-select w-auto ms-2"
          onChange={selectAuctionStatus}
          defaultValue={"status"}
        >
          <option value="status">{t("status")}</option>
          <option value="upcoming">{t("upcoming")}</option>
          <option value="live">{t("live")}</option>
          <option value="closed">{t("ended")}</option>
        </select>
        {isLoader && (
          <p className="text-center">
            <span className="spinner-border spinner-border-sm "></span> Loading
          </p>
        )}

        {!!properties?.length && (
          <Table responsive striped hover className="estate-table mt-3">
            <thead>
              <tr>
                <th>{t("thumbnail")}</th>
                <th>{t("title")}</th>
                <th>{t("asset_type")}</th>
                <th>{t("start_date")}</th>
                <th>{t("end_date")}</th>
                <th>{t("status")}</th>
                <th>{t("view_auction")}</th>
              </tr>
            </thead>
            <tbody>
              {properties?.length > 0 ? (
                properties.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <img
                        src={item?.auction.media_files?.[0]?.file}
                        alt="Thumbnail"
                        width="75"
                      />
                    </td>
                    <td>{item.auction.title}</td>
                    <td>{item.auction.asset_type}</td>
                    <td>{momentWithTZ(item.auction.start_date).format("L")}</td>
                    <td>{momentWithTZ(item.auction.end_date).format("L")}</td>
                    <td>{item.pay_status}</td>
                    <td>
                      <Link
                        to={`/auctions/${item.auction.auction_group_id}/${item.auction_id}`}
                      >
                        <button className="btn btn-base btn-sm">
                          {t("view_auction")}
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-dark text-center mt-2">
                    {t("no_data")}!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        {pagination?.total_pages > 1 && (
          <Paginator
            page={pagination.current_page}
            rows={pagination.per_page}
            totalRecords={pagination.total_records}
            onPageChange={handlePageChange}
          />
        )}
        {!isLoader && !properties?.length && (
          <div className="text-center mt-2">
            <button
              className="btn btn-base"
              onClick={() => navigate("/auctions")}
            >
              Explore Auctions
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuctionsList;
