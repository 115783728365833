import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { MdAddHome,MdSpaceDashboard,MdDownload } from "react-icons/md";
import logo from "../../assets/img/logo.png";
import google from "../../assets/img/google.png";
import apple from "../../assets/img/apple.svg";
import qr from "../../assets/img/qr-code.png"; // Add your QR code image here
import "./nav.css";
import { useTranslation } from "react-i18next";
import LanguageToggle from "../../components/LanguageToggle";

const CustomNavbar = () => {
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      expand="lg"
      className={`bg-white main-navbar shadow-sm p-3 rounded ${isSticky ? "sticky" : ""}`}
    >
      <Container fluid>
        <NavLink to="/">
          <Navbar.Brand className="mr-3">
            <img src={logo} height="60" width="auto" alt="Logo" />
          </Navbar.Brand>
        </NavLink>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mx-auto">
            <NavLink to="/" className="nav-link">
              {t("home")}
            </NavLink>
            <NavLink to="/about-us" className="nav-link">
              {t("about_us")}
            </NavLink>
            <NavLink to="/properties" className="nav-link">
              {t("real_estate")}
            </NavLink>
            <NavLink to="/auctions" className="nav-link">
              {t("auctions")}
            </NavLink>
            <NavLink to="/search-by-map" className="nav-link">
              {t("search")}
            </NavLink>
            <NavLink to="/request-service" className="nav-link">
              {t("request")}
            </NavLink>
            <NavLink to="/packages" className="nav-link">
              {t("packages")}
            </NavLink>
          </Nav>
          <Nav className="ml-auto d-flex align-items-center">
            <LanguageToggle />
            {/* App Download Dropdown */}
            <Dropdown align="end" className="mx-2">
              <Dropdown.Toggle variant="light" id="dropdown-download" className="bg-transparent border-0">
              <MdDownload className="mr-2" />
                {t("download_app")}
              </Dropdown.Toggle>
              <Dropdown.Menu className="text-center">
                {/* QR Code Image */}
                <Dropdown.Item>
                  <img src={qr} alt="QR Code" width="130px" />
                </Dropdown.Item>
                <Dropdown.Divider />
                {/* iOS App Store Link */}
                <Dropdown.Item as="a" href="https://apps.apple.com/sa/app/aqare/id6741023384" target="_blank" rel="noopener noreferrer">
                  <img src={apple} alt="Download on App Store" width="120px" />
                </Dropdown.Item>
                {/* Google Play Placeholder (Add link when available) */}
                <Dropdown.Item as="a" href="https://play.google.com/store/apps/details?id=net.aqare.aqareapp&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                  <img src={google} alt="Get it on Google Play" width="125px" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {user ? (
              <NavLink to="/dashboard" className="registerBtn text-decoration-none">
                <button className="btn  border border-dark btn-md p-2 d-flex align-items-center gap-2">
                  <MdSpaceDashboard className="mr-2" />
                  {t("dashboard")}
                </button>
              </NavLink>
            ) : (
              <NavLink to="/login" className="registerBtn text-decoration-none">
                <button className="btn border border-dark p-2 d-flex align-items-center gap-2">
                  <FaUserCircle className="mr-2" />
                  {t("login_register")}
                </button>
              </NavLink>
            )}
            <NavLink to="/nafath-auth" className="registerBtn mx-3 text-decoration-none">
              <button className="btn btn-base border btn-md p-2 d-flex align-items-center gap-2">
                <MdAddHome className="mr-2" />
                {t("ad_registration")}
              </button>
            </NavLink>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;

