import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import headerBanner from "../../assets/img/aqaresa-banner-sa.png";
import { Tabs, Tab, Dropdown, DropdownButton } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import ApiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang');
  const [openFilter, setOpenFilter] = useState(false);
  const [type, setType] = useState("Sell");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    id:0,
    name: t('select_category')
  });

  const [features, setFeatures] = useState([]);
  const [formData, setFormData] = useState({
    search: '',
    category_id: '',
    features:[],
    bedrooms: [0, 100],
    bathrooms: [0, 100],
    area: [0, 1000],
    price: [10, 500000000],
  });
  
  const handleFeatureChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => {
      const features = prevFormData.features.includes(value)
        ? prevFormData.features.filter((feature) => feature !== value) // remove if already exists
        : [...prevFormData.features, value]; // add if not exists
      return { ...prevFormData, features };
    });
  };
  const handleRangeChange = (name) => (value) => {
    setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
    }));
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
    getDropdownData();
  }, []);

  const getDropdownData = async () => {
    try {
      const response = await ApiService.request({
        method: 'GET',
        url: `getDropdownData?type=listing`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setCategories(data.data.categories);
        setFeatures(data.data.features);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSelect = (eventKey) => {
      console.log(eventKey);
      setType(eventKey);
  };

  const redirectFilter =  () => {
    formData.type=type;
    formData.category_id=selectedCategory.id;
    console.log(formData);
    const encodedData = encodeURIComponent(JSON.stringify(formData));
    navigate(`/properties?filterData=${encodedData}`);
    // navigate('/properties')
  };

  return (
    <div>
      <div className="container-fluid header">
      <div className="video-background">
        <iframe
          src="https://www.youtube.com/embed/zfujw9DrPLg?controls=0&rel=0&playsinline=1&autoplay=1&mute=1&loop=1&playlist=zfujw9DrPLg&enablejsapi=1&origin=https%3A%2F%2Faqare.sa"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="Background Video"
        ></iframe>
      </div>


        <div className="header-filter">
          <img
            src={headerBanner}
            className=" header-img"
            alt="Header Image"
            data-aos="fade-left"
          />

          <div className="main-search" data-aos="fade-right">
            <h1 className="text-base mt-5">
              {t("real_platform")}
            </h1>
            <h3 className="text-secondary">
              {t("buy")}
            </h3>
            <br />
            <br />
            <Tabs defaultActiveKey="Sell"  onSelect={handleSelect } style={{padding:'0 10px',border:'none'}}>
              <Tab eventKey="Sell" title={t('sell')} className="sell-tab" />
              <Tab eventKey="Rent" title={t('rent')}  className="rent-tab" />
            </Tabs>

            <div className="search-details align-items-center ">
              <div className="search-input bg-white">
                <input
                  name="search"
                  onChange={handleChange}
                  type="text"
                  placeholder={t('enter_keyword')}
                  className="keyword-input"
                />
              </div>
              <div className="category-select">
                <DropdownButton
                  variant="outline-secondary"
                  title={selectedCategory.name}
                  id="input-group-dropdown"
                  align="end"
                  className="bg-white select-cat border-0"
                >
                  {categories.map((category) => (
                    <Dropdown.Item key={category.id} onClick={() => setSelectedCategory(category)}  >
                      {category.name}
                    </Dropdown.Item>
                  ))}
          
                </DropdownButton>
              </div>
                  <div className={lang=='ar'? 'filter-icon icon-ar':'filter-icon'} onClick={() => setOpenFilter(!openFilter)} >
                    <i className="bi bi-filter-circle"></i>
                  </div>

                  {openFilter && 
                    <div className="search-filter">
                        <div className="row">
                          {features.map((feature, index) => (
                            <div className="col-6 col-md-4 mb-2" key={index}>
                              <label className="">
                                <input type="checkbox" className="form-check-input large-checkbox" value={feature.name} name='features' 
                                  checked={formData.features.includes(feature.name)}  onChange={handleFeatureChange}/>
                                  {feature.name} 
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="row">
                            <div className="col-4 col-md-3 mt-3">
                              <div className="filterBox">
                                <p>{t("bedrooms")}</p>
                                <RangeSlider    value={formData.bedrooms}
                                 onInput={handleRangeChange('bedrooms')}
                                  min={0}  max={100}   step={1}
                                />
                                <div className="d-flex pt-2 pb-2 priceRange">
                                  <span>
                                    <small className="text-success">{formData.bedrooms[0]}</small>
                                  </span>
                                  <div className="ml-auto">
                                    - <small className="text-success">{formData.bedrooms[1]}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-md-3 mt-3">
                              <div className="filterBox">
                                <p>{t("the_bathroom")}</p>
                                <RangeSlider     value={formData.bathrooms}
                                 onInput={handleRangeChange('bathrooms')}
                                  min={0}  max={100}   step={1}
                                />
                                <div className="d-flex pt-2 pb-2 priceRange">
                                  <span>
                                    <small className="text-success">{formData.bathrooms[0]}</small>
                                  </span>
                                  <div className="ml-auto">
                                    - <small className="text-success">{formData.bathrooms[1]}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-md-3 mt-3">
                              <div className="filterBox">
                                <p>{t("area")}</p>
                                <RangeSlider   value={formData.area}
                                 onInput={handleRangeChange('area')}
                                  min={0}  max={100}   step={1}
                                />
                                <div className="d-flex pt-2 pb-2 priceRange">
                                  <span>
                                    <small className="text-success">{formData.area[0]}</small>
                                  </span>
                                  <div className="ml-auto">
                                    - <small className="text-success">{formData.area[1]}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-md-3 mt-3">
                              <div className="filterBox">
                                <p>{t("price")} ({t("currency")})</p>
                                <RangeSlider   value={formData.price}
                                 onInput={handleRangeChange('price')}
                                  min={10}  max={5000000}   step={1000}
                                />
                                <div className="d-flex pt-2 pb-2 priceRange">
                                  <span>
                                    <small className="text-success">{formData.price[0]}</small>
                                  </span>
                                  <div className="ml-auto">
                                    - <small className="text-success">{formData.price[1]}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        
                    </div>
                  }
                      
            
              <div className="search-btn">
                <button onClick={redirectFilter}>
                  {t("search_cat")} <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
            <p className="lead my-4">
              {t("header_para")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
