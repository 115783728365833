import React, { useEffect } from "react";
import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const AuctionTermsConditions = () => {
  const { t } = useTranslation();
  const app_lang = localStorage.getItem("lang") || "en";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Topbar />
      <CustomNavbar />

      <div className="estate-header">
        <div className="header-txt text-white">
          <p>
            <NavLink to="/" className="nav-link d-inline">
              {t("home")}
            </NavLink>
            <i
              className={`bi ${
                app_lang === "en" ? "bi-chevron-right" : "bi-chevron-left"
              }`}
            ></i>{" "}
            {t("auction_terms")}
          </p>
        </div>
      </div>

      <div className="container mb-5">
        <div className="bg-white mt-5 p-5">
          {app_lang === "en" ? (
            <div className="main-en">
              <h5>Terms and Conditions</h5>
              <p>
                Document of Terms and Conditions for the Use of Aqare’s Real
                Estate Electronic Auction Platform.
              </p>

              <h5>Article 1: Definitions and Interpretations</h5>
              <p>
                Unless the context requires otherwise, the following terms have
                the meanings specified for each:
              </p>
              <ul>
                <li>
                  <strong>Auction:</strong> A public sale conducted on the
                  Website through bidding to purchase Real Estate Units/Assets
                  by Users.
                </li>
                <li>
                  <strong>Bid Deposit:</strong> An amount set by the relevant
                  authority (Infath) that is determined based on the Asset.
                </li>
                <li>
                  <strong>Bidding Period:</strong> The period that starts from
                  the first day of bidding until the last date specified by
                  Infath.
                </li>
                <li>
                  <strong>Completion Period:</strong> As defined in Article 5.
                </li>
                <li>
                  <strong>Document Validity Period:</strong> The date the User
                  and/or Sales Agent accepts these Terms and Conditions.
                </li>
                <li>
                  <strong>Infath:</strong> A governmental center specialized in
                  entrustment and liquidation.
                </li>
                <li>
                  <strong>Listing Period:</strong> The period that starts from
                  listing the Assets and continues until the Bidding Period
                  starts.
                </li>
                <li>
                  <strong>Platform:</strong> An electronic platform owned by the
                  Company which offers auction and public sale services for real
                  estate assets displayed on the Website.
                </li>
                <li>
                  <strong>Real Estate Unit(s) or Assets:</strong> Lands or
                  buildings displayed for Auction on the Website by the Sales
                  Agent.
                </li>
                <li>
                  <strong>Sales Agent:</strong> A natural or legal person
                  authorized to dispose of the Real Estate Unit through a valid
                  agency.
                </li>
                <li>
                  <strong>User:</strong> A natural or legal person who confirms
                  their intention to participate in the Auction according to the
                  Terms and Conditions.
                </li>
                <li>
                  <strong>Website:</strong> The website owned by Aqare under the
                  domain <a href="https://aqare.sa">https://aqare.sa</a>.
                </li>
              </ul>

              <h5>
                Article 2: Eligibility and Agreement to Terms and Conditions
              </h5>
              <p>
                These Terms and Conditions constitute a binding contract between
                the Company and the User and/or Sales Agent, specifying the
                responsibilities of both Parties. The User and/or Sales Agent
                must carefully read these Terms and Conditions along with the
                Terms of Advertising, Terms of Use, and Privacy Policy which can
                be found on the Website before using the Auction services. The
                User and/or Sales Agent acknowledges being aware of and agrees
                to these Terms and Conditions, disclaiming ignorance, and
                accepts their obligation to abide by them from the moment they
                click the icon that states: "I acknowledge that I am fully
                eligible, and I have read and agree to the terms and
                conditions." At that point, the Terms and Conditions of this
                document, the Terms of Advertising, Terms of Use, and Privacy
                Policy all become binding upon the User and/or Sales Agent, and
                the "Document Validity Period" commences. The User and/or Sales
                Agent also agrees that the Company has the right to modify these
                Terms and Conditions without prior consent or individual
                notification, by publishing the amendments on the Website. The
                User and/or Sales Agent's continued use of the Website after the
                date of such amendments constitutes acceptance of these modified
                terms and conditions, and the User and/or Sales Agent
                acknowledges and agrees to comply with them.
              </p>

              <h5>Article 3: Purpose of the Auction Platform</h5>
              <p>
                The Company has established the electronic Website and Platform,
                for the purpose of allowing Sales Agent to showcase the Real
                Estate Units on the Website for bidding by bidders in an
                Auction. The Company acts as an intermediary between the Sales
                Agent and bidders. These Terms and Conditions outline the
                obligations and procedures related to this process.
              </p>

              <h5>
                Article 4: Registration Requirements, Declarations, and Ongoing
                Commitments
              </h5>
              <p>
                Every individual or legal entity wishing to participate in the
                Auction must create a user account on the Website and comply
                with the following requirements and conditions for creating a
                user account:
              </p>

              <h6>Requirements for Natural Persons Registration:</h6>
              <ul>
                <li>
                  The individual must be at least 18 years old and confirm that
                  they have full legal capacity to accept and abide by these
                  Terms and Conditions.
                </li>
                <li>
                  The individual must be of Saudi nationality, or if a
                  non-Saudi, they must be among those allowed by the regulations
                  to own assets in the Kingdom of Saudi Arabia and provide
                  evidence of that.
                </li>
                <li>
                  The individual must provide their identification, address,
                  contact number(s), and any other supporting information
                  required by the Company.
                </li>
                <li>
                  The individual must not be representing any other party or
                  person or use the Website on behalf of others, except when
                  acting as an agent for another person authorized to register
                  on the Website, accept the Terms and Conditions, and perform
                  real estate transactions on behalf of the principal. In such a
                  case, the person shall provide appropriate supporting
                  documentation for the authorization.
                </li>
              </ul>

              <h6>
                Requirements for Legal Entities Registration (Companies,
                Institutions, or Charities):
              </h6>
              <ul>
                <li>
                  The company must be fully owned by Saudi individuals, or if a
                  mixed company, it must be licensed by the competent authority
                  to own assets in the Kingdom of Saudi Arabia.
                </li>
                <li>
                  Provide a copy of a valid commercial registration or relevant
                  license in case of charities.
                </li>
                <li>
                  Provide a copy of the Articles of Association or the founding
                  contract, as applicable.
                </li>
                <li>
                  Provide evidence of the administrator's authority to register
                  on behalf of the company, accept the Terms and Conditions, and
                  perform real estate transactions on behalf of the company.
                </li>
              </ul>

              <h6>Declarations and Ongoing Commitments:</h6>
              <ul>
                <li>
                  The User and/or Sales Agent declares that they are aware of
                  and knowledgeable about the regulations related to asset
                  ownership, including those concerning foreign ownership and
                  ownership in Makkah and Madinah, and that they comply with
                  those regulations and all other necessary regulations to use
                  the Website in accordance with the Terms and Conditions.
                </li>
                <li>
                  The User declares and undertakes that all information provided
                  by them is accurate, precise, and not misleading.
                </li>
                <li>
                  The User declares and undertakes not to grant others access to
                  their account.
                </li>
                <li>
                  The User undertakes not to bid on behalf of any other natural
                  person or legal entity unless authorized to do so, provided
                  that such authorization is submitted and accepted by the
                  Company.
                </li>
                <li>
                  The User undertakes not to use any automated processes to
                  process, monitor, copy, or extract any pages existing on the
                  Website or any information or data contained therein, or
                  accessed through the Website, or materials or data accessed or
                  sourced from third parties.
                </li>
                <li>
                  The User undertakes not to perform any actions to interfere
                  with or attempt to interfere with the proper functioning of
                  the Website or to perform any act that would impose an
                  unreasonable or disproportionately large load on the available
                  infrastructure.
                </li>
                <li>
                  The User undertakes not to engage in reverse engineering,
                  reverse compilation, disassembly, or other activities to
                  discover the program formulas or processes related to the
                  computer program used in the infrastructure and operations of
                  the Website.
                </li>
                <li>
                  The User also undertakes not to copy, reproduce, modify,
                  derive works from, or publicly display any portion of the
                  content of the Website without prior written consent from the
                  Company.
                </li>
                <li>
                  The User declares, acknowledges, and agrees that the Company
                  is not and will not be responsible for determining the
                  starting price, Bid Deposits, and awarding bids.
                </li>
                <li>
                  The User acknowledges and agrees that the Company and Infath
                  have the right to modify or cancel any Auction before the
                  Auction starts, including but not limited to modifying
                  property descriptions, Bid Deposits, bidding starting amounts,
                  Bidding Period, and additional terms. These modifications or
                  cancellations may occur to ensure fairness, address unforeseen
                  circumstances, correct data, or maintain platform integrity
                  and security.
                </li>
                <li>
                  The User acknowledges and agrees that the Company has the
                  right to make any modifications related to the Auction after
                  it starts, based on Infath’s instructions.
                </li>
                <li>
                  The User acknowledges and agrees that the Company has the
                  right to cancel the Auction after it starts. This is done by
                  authorized individuals within the Company, subject to their
                  designated authority.
                </li>
                <li>
                  The Company will make every effort to maintain transparency in
                  the Auction, and Users will receive notifications in the event
                  of any major changes. Users may contact customer service to
                  express concerns or obtain assistance.
                </li>
                <li>
                  The User acknowledges and agrees that bids submitted during
                  the Auction will be subject to additional terms and conditions
                  specific to that Auction, and they should consider these terms
                  before bidding.
                </li>
                <li>
                  In cases where a significant change occurs after bidding has
                  begun, Users who have placed their bids may contact customer
                  service to discuss withdrawal options.
                </li>
                <li>
                  If an Auction is canceled before or after it starts, the
                  Company will send a notification via SMS to all participating
                  Users at their registered phone numbers to ensure
                  transparency.
                </li>
                <li>
                  If an Auction is canceled before or after it starts, the
                  Company is obligated to immediately refund the Down Payment to
                  the User account of the User who made the payment to
                  participate in the canceled Auction.
                </li>
              </ul>

              <h6>
                Responsibilities Arising from Violation of Declarations and
                Commitments:
              </h6>
              <ul>
                <li>
                  The Company shall not be held responsible for any breach or
                  violation of the User's commitments and declarations in this
                  document.
                </li>
                <li>
                  The Company reserves the right to cancel the User's
                  registration and seize all fees, deposits, or bids made by the
                  User.
                </li>
                <li>
                  The User acknowledges and undertakes to defend and indemnify
                  the Company for any costs and any losses arising from their
                  violation of these terms.
                </li>
              </ul>

              <h5>Article 5: Platform Terms of Use</h5>
              <p>
                The Company displays Assets available for Auction and their
                relevant duration on the Website, and the opportunity for
                inspection is arranged directly with the Sales Agent.
              </p>
              <p>
                The Company notifies Users -who agree to receive notifications
                through their accounts- about new Assets available for Auction.
              </p>
              <p>
                If a User wishes to participate in the bidding for an Asset,
                they must deposit the Bid Deposit, as indicated in the Asset's
                advertisement on the Website.
              </p>
              <p>
                The User can register and bid on multiple Assets simultaneously
                provided that they pay the Bid Deposit for each Asset they would
                like to bid on.
              </p>
              <p>
                The User who paid the Bid Deposit has the right to enter the
                bidding process during the Bidding Period as long as the Sales
                Agent has not withdrawn the Asset before the end of the Bidding
                Period due to a court order. In case the Asset is withdrawn for
                that reason, the Bid Deposit is refunded to the User.
              </p>
              <p>
                The Bidding Deposit will be held during the Bidding Period for
                all participants and will be released once the Bidding Period is
                over unless stated otherwise in these Terms and Conditions.
              </p>
              <p>
                The User can withdraw their bid during the Bidding Period and
                the Bidding Deposit will be released then as long as the User is
                not the highest bidder.
              </p>
              <p>
                If the User's bid is the highest at the end of the Bidding
                Period, and the bid is accepted by Infath, the User will be
                notified accordingly. The User must then directly coordinate
                with the Sales Agent to finalize the sale and document it.
              </p>
              <p>
                If the User who was awarded the bid withdraws or fails to
                complete the sale, the Company will seize the Bid Deposit paid
                by the User and will have the right to cancel the User’s account
                and participation.
              </p>
              <p>
                The User acknowledges and agrees that the Company does not
                guarantee the completion of Asset sales and assumes no
                responsibility regarding the Sales Agent's breach or withdrawal.
              </p>
              <p>
                The Bid Deposit will be transferred to the relevant authority
                after the bid is awarded.
              </p>
              <p>
                The winning User who was awarded the bid and the Sales Agent are
                jointly responsible for any government fees or taxes resulting
                from the completion of the sale, and the Company does not bear
                any of these fees. If the Company is charged for these amounts
                for any reason, it has the right to recover them jointly from
                both parties.
              </p>

              <h5>Article 6: Fee Payment</h5>
              <p>
                Users must pay amounts due through the Website using a credit or
                debit card.
              </p>

              <h5>Article 7: Asset Inspection</h5>
              <p>
                The User acknowledges that they have been given the opportunity
                to inspect the Asset, and their payment of the Bid Deposit
                constitutes an acknowledgment of this fact, and they are not
                entitled to withdraw from it. The User cannot hold the Company
                liable for any damages resulting from any defects in the Asset,
                and the Company does not provide any warranties, commitments, or
                obligations in this regard.
              </p>

              <h5>Article 8: Legal Liability and Compensation</h5>
              <p>
                The Company shall not be held liable for unauthorized use of
                User accounts.
              </p>

              <h5>Article 9: Intellectual Property</h5>
              <p>
                All intellectual property rights in materials on the Website are
                owned by the Company or its licensors.
              </p>

              <h5>Article 10: Duration of Agreement and Termination</h5>
              <p>
                These Terms and Conditions remain in effect while the User uses
                the Website.
              </p>

              <h5>
                Article 11: Legal Inapplicability and Independence of Provisions
              </h5>
              <p>
                If any provision is deemed illegal, it shall not affect the
                validity of other provisions.
              </p>

              <h5>Article 12: Applicable Law and Dispute Resolution</h5>
              <p>
                These Terms and Conditions are subject to the laws of the
                Kingdom of Saudi Arabia. In the event of a dispute between the
                User and/or Sales Agent and the Company regarding these Terms
                and Conditions, their violation, termination, implementation,
                interpretation, validity, or the use of the Website, Platform,
                or services, such disputes shall be settled amicably, by
                contacting the Platform's customer support team. If the dispute
                is not resolved amicably within 60 days from the date of
                notification of the dispute by one of the Parties to the other,
                the Parties agree that the dispute shall be exclusively referred
                to and settled by the relevant courts of Riyadh, in accordance
                with the laws applicable in the Kingdom of Saudi Arabia.
              </p>
            </div>
          ) : (
            <div className="main-ar">
              <h5>الشروط والأحكام</h5>
              <p>
                وثيقة الشروط والأحكام لاستخدام منصة المزادات الإلكترونية الخاصة
                بـ خطوات العقار.
              </p>

              <h5>المادة 1: التعريفات والتفسيرات</h5>
              <p>
                ما لم يقتضِ السياق خلاف ذلك، يكون للمصطلحات التالية المعاني
                المحددة لكل منها:
              </p>
              <ul>
                <li>
                  <strong>المزاد:</strong> بيع علني يُجرى على الموقع الإلكتروني
                  من خلال المزايدة لشراء وحدات/أصول عقارية من قبل المستخدمين.
                </li>
                <li>
                  <strong>إيداع المزايدة:</strong> مبلغ تحدده الجهة المختصة
                  (إنفاذ) بناءً على الأصل المعروض.
                </li>
                <li>
                  <strong>فترة المزايدة:</strong> الفترة التي تبدأ من اليوم
                  الأول للمزايدة حتى التاريخ الأخير المحدد من قبل إنفاذ.
                </li>
                <li>
                  <strong>فترة الإتمام:</strong> كما هو محدد في المادة 5.
                </li>
                <li>
                  <strong>مدة صلاحية المستند:</strong> تاريخ قبول المستخدم و/أو
                  وكيل البيع لهذه الشروط والأحكام.
                </li>
                <li>
                  <strong>إنفاذ:</strong> مركز حكومي متخصص في الإسناد والتصفية.
                </li>
                <li>
                  <strong>فترة الإدراج:</strong> الفترة التي تبدأ من إدراج
                  الأصول وتستمر حتى بداية فترة المزايدة.
                </li>
                <li>
                  <strong>المنصة:</strong> منصة إلكترونية مملوكة من قبل الشركة
                  تقدم خدمات المزاد والبيع العلني للأصول العقارية المعروضة على
                  الموقع الإلكتروني.
                </li>
                <li>
                  <strong>الوحدة العقارية أو الأصول:</strong> الأراضي أو المباني
                  المعروضة في المزاد على الموقع الإلكتروني من قبل وكيل البيع.
                </li>
                <li>
                  <strong>وكيل البيع:</strong> شخص طبيعي أو اعتباري مخول بالتصرف
                  في الوحدة العقارية من خلال وكالة سارية المفعول.
                </li>
                <li>
                  <strong>المستخدم:</strong> شخص طبيعي أو اعتباري يؤكد نيته في
                  المشاركة في المزاد وفقًا للشروط والأحكام.
                </li>
                <li>
                  <strong>الموقع الإلكتروني:</strong> الموقع الإلكتروني المملوك
                  من قبل عقاري تحت النطاق{" "}
                  <a href="https://aqare.sa">https://aqare.sa</a>.
                </li>
              </ul>

              <h5>المادة 2: الأهلية والموافقة على الشروط والأحكام</h5>
              <p>
                تشكل هذه الشروط والأحكام عقدًا ملزمًا بين الشركة والمستخدم و/أو
                وكيل البيع، يحدد مسؤوليات كلا الطرفين. يجب على المستخدم و/أو
                وكيل البيع قراءة هذه الشروط والأحكام بعناية إلى جانب شروط
                الإعلان، شروط الاستخدام، وسياسة الخصوصية المتاحة على الموقع
                الإلكتروني قبل استخدام خدمات المزاد. يقر المستخدم و/أو وكيل
                البيع بأنه على علم بهذه الشروط والأحكام ويوافق عليها ويتنازل عن
                أي ادعاء بعدم العلم بها، كما يوافق على الالتزام بها من اللحظة
                التي يقوم فيها بالنقر على الأيقونة التي تنص على: "أقر بأنني مؤهل
                بالكامل، وقد قرأت وأوافق على الشروط والأحكام." في هذه اللحظة،
                تصبح هذه الشروط والأحكام بالإضافة إلى شروط الإعلان، شروط
                الاستخدام، وسياسة الخصوصية ملزمة للمستخدم و/أو وكيل البيع، وتبدأ
                "مدة صلاحية المستند". كما يوافق المستخدم و/أو وكيل البيع على أن
                للشركة الحق في تعديل هذه الشروط والأحكام دون الحاجة إلى موافقة
                مسبقة أو إشعار فردي، وذلك من خلال نشر التعديلات على الموقع
                الإلكتروني. إن استمرار المستخدم و/أو وكيل البيع في استخدام
                الموقع الإلكتروني بعد تاريخ هذه التعديلات يشكل قبولًا لهذه
                التعديلات، كما يقر المستخدم و/أو وكيل البيع بالتزامه بها.
              </p>

              <h5>المادة 3: الغرض من منصة المزاد</h5>
              <p>
                أنشأت الشركة الموقع الإلكتروني والمنصة الإلكترونية لغرض تمكين
                وكيل البيع من عرض الوحدات العقارية على الموقع للمزايدة من قبل
                المزايدين في المزاد. تعمل الشركة كوسيط بين وكيل البيع
                والمزايدين. تحدد هذه الشروط والأحكام الالتزامات والإجراءات
                المتعلقة بهذه العملية.
              </p>

              <h5>
                المادة 4: متطلبات التسجيل، الإقرارات، والالتزامات المستمرة
              </h5>
              <p>
                يجب على كل فرد أو كيان قانوني يرغب في المشاركة في المزاد إنشاء
                حساب مستخدم على الموقع والامتثال للمتطلبات والشروط التالية
                لإنشاء حساب المستخدم:
              </p>

              <h6>متطلبات تسجيل الأشخاص الطبيعيين:</h6>
              <ul>
                <li>
                  يجب أن يكون الفرد لا يقل عن 18 عامًا ويؤكد أن لديه الأهلية
                  القانونية الكاملة لقبول والامتثال لهذه الشروط والأحكام.
                </li>
                <li>
                  يجب أن يكون الفرد سعودي الجنسية، أو إذا كان غير سعودي، يجب أن
                  يكون من الفئات المسموح لها بامتلاك الأصول في المملكة العربية
                  السعودية، ويجب عليه تقديم ما يثبت ذلك.
                </li>
                <li>
                  يجب على الفرد تقديم هويته، وعنوانه، ورقم الاتصال الخاص به، وأي
                  معلومات داعمة أخرى تطلبها الشركة.
                </li>
                <li>
                  يجب ألا يكون الفرد ممثلاً لأي طرف آخر أو شخص آخر أو يستخدم
                  الموقع نيابةً عن الآخرين، إلا إذا كان يعمل كوكيل لشخص آخر مخول
                  بالتسجيل في الموقع وقبول الشروط والأحكام وإجراء معاملات
                  العقارات نيابة عن الموكل، وفي هذه الحالة يجب تقديم الوثائق
                  الداعمة المناسبة للتفويض.
                </li>
              </ul>

              <h6>
                متطلبات تسجيل الكيانات القانونية (الشركات، المؤسسات، أو الجمعيات
                الخيرية):
              </h6>
              <ul>
                <li>
                  يجب أن تكون الشركة مملوكة بالكامل لأفراد سعوديين، أو إذا كانت
                  شركة مختلطة، يجب أن تكون مرخصة من الجهة المختصة لامتلاك الأصول
                  في المملكة العربية السعودية.
                </li>
                <li>
                  تقديم نسخة من السجل التجاري الساري أو الترخيص ذي الصلة في حالة
                  الجمعيات الخيرية.
                </li>
                <li>
                  تقديم نسخة من عقد التأسيس أو النظام الأساسي، حسبما يكون
                  مناسبًا.
                </li>
                <li>
                  تقديم إثبات صلاحية المدير أو الممثل القانوني لتسجيل الشركة،
                  قبول الشروط والأحكام، وإجراء معاملات العقارات نيابة عن الشركة.
                </li>
              </ul>

              <h6>الإقرارات والالتزامات المستمرة:</h6>
              <ul>
                <li>
                  يقر المستخدم و/أو وكيل البيع بأنه على دراية واطلاع بالأنظمة
                  المتعلقة بملكية الأصول، بما في ذلك اللوائح الخاصة بملكية
                  الأجانب والملكية في مكة والمدينة، وأنه يمتثل لهذه الأنظمة
                  وجميع الأنظمة الأخرى اللازمة لاستخدام الموقع وفقًا للشروط
                  والأحكام.
                </li>
                <li>
                  يقر المستخدم ويتعهد بأن جميع المعلومات التي يقدمها دقيقة
                  وصحيحة وليست مضللة.
                </li>
                <li>
                  يقر المستخدم ويتعهد بعدم السماح للآخرين بالوصول إلى حسابه.
                </li>
                <li>
                  يتعهد المستخدم بعدم المزايدة نيابةً عن أي شخص طبيعي أو كيان
                  قانوني آخر ما لم يكن مخولًا بذلك، بشرط تقديم هذا التخويل
                  وقبوله من قبل الشركة.
                </li>
                <li>
                  يتعهد المستخدم بعدم استخدام أي عمليات آلية لمعالجة أو مراقبة
                  أو نسخ أو استخراج أي صفحات موجودة على الموقع أو أي معلومات أو
                  بيانات موجودة فيه أو الوصول إليها من خلال الموقع، أو أي مواد
                  أو بيانات يتم الوصول إليها أو الحصول عليها من أطراف ثالثة.
                </li>
                <li>
                  يتعهد المستخدم بعدم القيام بأي إجراءات من شأنها التدخل أو
                  محاولة التدخل في الوظائف السليمة للموقع، أو تنفيذ أي إجراء من
                  شأنه تحميل البنية التحتية المتاحة عبئًا غير معقول أو مفرط.
                </li>
                <li>
                  يتعهد المستخدم بعدم القيام بأي عمليات عكسية، أو إعادة تجميع،
                  أو تفكيك، أو أي أنشطة أخرى لاكتشاف الصيغ أو العمليات البرمجية
                  المتعلقة بالبرنامج المستخدم في البنية التحتية وعمليات الموقع.
                </li>
                <li>
                  يتعهد المستخدم بعدم نسخ أو إعادة إنتاج أو تعديل أو اشتقاق
                  أعمال من أي جزء من محتوى الموقع أو عرضه علنًا بدون موافقة
                  كتابية مسبقة من الشركة.
                </li>
                <li>
                  يقر المستخدم ويوافق على أن الشركة ليست ولن تكون مسؤولة عن
                  تحديد سعر البدء أو الودائع أو منح العطاءات.
                </li>
                <li>
                  يقر المستخدم ويوافق على أن الشركة وإنفاذ يحق لهما تعديل أو
                  إلغاء أي مزاد قبل بدئه، بما في ذلك تعديل وصف العقار، وديعة
                  المزايدة، ومبلغ البدء في المزايدة، وفترة المزايدة، والشروط
                  الإضافية، وذلك لضمان النزاهة أو معالجة الظروف غير المتوقعة أو
                  تصحيح البيانات أو الحفاظ على سلامة وأمان المنصة.
                </li>
                <li>
                  يقر المستخدم ويوافق على أن للشركة الحق في إجراء أي تعديلات على
                  المزاد بعد أن يبدأ، بناءً على تعليمات إنفاذ.
                </li>
                <li>
                  يقر المستخدم ويوافق على أن للشركة الحق في إلغاء المزاد بعد أن
                  يبدأ، ويتم ذلك من قبل الأفراد المخولين داخل الشركة وفقًا
                  لسلطاتهم المحددة.
                </li>
                <li>
                  ستبذل الشركة كل جهد للحفاظ على شفافية المزاد، وسيتلقى
                  المستخدمون إشعارات في حالة حدوث أي تغييرات رئيسية. يمكن
                  للمستخدمين التواصل مع خدمة العملاء للإبلاغ عن مخاوفهم أو
                  الحصول على المساعدة.
                </li>
                <li>
                  يقر المستخدم ويوافق على أن العطاءات المقدمة أثناء المزاد ستكون
                  خاضعة لشروط وأحكام إضافية خاصة بذلك المزاد، ويجب على المستخدم
                  مراجعة هذه الشروط قبل المزايدة.
                </li>
                <li>
                  في حالة حدوث تغيير كبير بعد بدء المزايدة، يمكن للمستخدمين
                  الذين قاموا بتقديم عروضهم التواصل مع خدمة العملاء لمناقشة
                  خيارات الانسحاب.
                </li>
                <li>
                  إذا تم إلغاء المزاد قبل أن يبدأ أو بعده، ستقوم الشركة بإرسال
                  إشعار عبر الرسائل النصية القصيرة إلى جميع المستخدمين المشاركين
                  على أرقام هواتفهم المسجلة لضمان الشفافية.
                </li>
                <li>
                  إذا تم إلغاء المزاد قبل أن يبدأ أو بعده، تلتزم الشركة فورًا
                  بإعادة المبلغ المدفوع كوديعة للمشاركة في المزاد الملغى إلى
                  حساب المستخدم الذي قام بالدفع.
                </li>
              </ul>

              <h6>المسؤوليات الناشئة عن انتهاك الإقرارات والالتزامات:</h6>
              <ul>
                <li>
                  لن تكون الشركة مسؤولة عن أي خرق أو انتهاك من قبل المستخدم
                  لالتزاماته وإقراراته الواردة في هذا المستند.
                </li>
                <li>
                  تحتفظ الشركة بحق إلغاء تسجيل المستخدم ومصادرة جميع الرسوم أو
                  الودائع أو العطاءات التي قدمها المستخدم.
                </li>
                <li>
                  يقر المستخدم ويتعهد بالدفاع عن الشركة وتعويضها عن أي تكاليف أو
                  خسائر ناتجة عن انتهاكه لهذه الشروط.
                </li>
              </ul>

              <h5>المادة 5: شروط استخدام المنصة</h5>
              <p>
                تعرض الشركة الأصول المتاحة للمزاد والفترة الزمنية الخاصة بها على
                الموقع، ويتم ترتيب فرصة المعاينة مباشرة مع وكيل البيع.
              </p>
              <p>
                تقوم الشركة بإشعار المستخدمين - الذين يوافقون على تلقي الإشعارات
                من خلال حساباتهم - حول الأصول الجديدة المتاحة للمزاد.
              </p>
              <p>
                إذا رغب المستخدم في المشاركة في المزايدة على أحد الأصول، فيجب
                عليه إيداع مبلغ التأمين للمزايدة، كما هو موضح في إعلان الأصل على
                الموقع.
              </p>
              <p>
                يمكن للمستخدم التسجيل والمزايدة على عدة أصول في نفس الوقت، بشرط
                دفع مبلغ التأمين الخاص بكل أصل يرغب في المزايدة عليه.
              </p>
              <p>
                يحق للمستخدم الذي دفع مبلغ التأمين للمزايدة الدخول في عملية
                المزايدة خلال فترة المزايدة، طالما أن وكيل البيع لم يسحب الأصل
                قبل نهاية فترة المزايدة بأمر من المحكمة. في حالة سحب الأصل لهذا
                السبب، يتم استرداد مبلغ التأمين للمستخدم.
              </p>
              <p>
                سيتم الاحتفاظ بمبلغ التأمين خلال فترة المزايدة لجميع المشاركين،
                وسيتم إرجاعه بعد انتهاء فترة المزايدة ما لم ينص على خلاف ذلك في
                هذه الشروط والأحكام.
              </p>
              <p>
                يمكن للمستخدم سحب مزايدته خلال فترة المزايدة، وسيتم تحرير مبلغ
                التأمين في هذه الحالة، بشرط ألا يكون المستخدم هو صاحب أعلى
                مزايدة.
              </p>
              <p>
                إذا كانت مزايدة المستخدم هي الأعلى في نهاية فترة المزايدة، وتم
                قبول المزايدة من قبل "إنفاذ"، فسيتم إخطار المستخدم بذلك. عندها،
                يجب على المستخدم التنسيق مباشرة مع وكيل البيع لاستكمال إجراءات
                البيع وتوثيقه.
              </p>
              <p>
                إذا انسحب المستخدم الفائز بالمزايدة أو فشل في إتمام عملية البيع،
                فستقوم الشركة بمصادرة مبلغ التأمين المدفوع من قبل المستخدم، ويحق
                لها إلغاء حساب المستخدم ومنعه من المشاركة مستقبلاً.
              </p>
              <p>
                يقر المستخدم ويوافق على أن الشركة لا تضمن إتمام عمليات بيع
                الأصول ولا تتحمل أي مسؤولية عن إخلال وكيل البيع أو انسحابه من
                العملية.
              </p>
              <p>سيتم تحويل مبلغ التأمين إلى الجهة المختصة بعد منح المزايدة.</p>
              <p>
                يكون المستخدم الفائز بالمزايدة ووكيل البيع مسؤولين بشكل مشترك عن
                أي رسوم حكومية أو ضرائب ناتجة عن إتمام عملية البيع، ولا تتحمل
                الشركة أي من هذه الرسوم. وفي حال تم تحميل الشركة هذه المبالغ لأي
                سبب من الأسباب، يحق لها استردادها من الطرفين معًا.
              </p>

              <h5>المادة 6: دفع الرسوم</h5>
              <p>
                يجب على المستخدمين دفع المبالغ المستحقة من خلال الموقع باستخدام
                بطاقة الائتمان أو بطاقة الخصم.
              </p>

              <h5>المادة 7: معاينة الأصل</h5>
              <p>
                يقر المستخدم بأنه تم منحه الفرصة لمعاينة الأصل، وأن دفعه لمبلغ
                التأمين للمزايدة يشكل إقرارًا بهذا الأمر، ولا يحق له الانسحاب
                منه. لا يمكن للمستخدم تحميل الشركة أي مسؤولية عن أي أضرار ناتجة
                عن عيوب في الأصل، كما أن الشركة لا تقدم أي ضمانات أو التزامات أو
                تعهدات بهذا الشأن.
              </p>

              <h5>المادة 8: المسؤولية القانونية والتعويض</h5>
              <p>
                لا تتحمل الشركة أي مسؤولية عن الاستخدام غير المصرح به لحسابات
                المستخدمين.
              </p>

              <h5>المادة 9: الملكية الفكرية</h5>
              <p>
                جميع حقوق الملكية الفكرية للمواد الموجودة على الموقع مملوكة
                للشركة أو الجهات المرخصة لها.
              </p>

              <h5>المادة 10: مدة الاتفاقية وإنهاؤها</h5>
              <p>
                تبقى هذه الشروط والأحكام سارية المفعول أثناء استخدام المستخدم
                للموقع.
              </p>

              <h5>
                المادة 11: عدم قابلية التطبيق القانونية واستقلالية الأحكام
              </h5>
              <p>
                إذا تم اعتبار أي بند غير قانوني، فلن يؤثر ذلك على صلاحية الأحكام
                الأخرى.
              </p>

              <h5>المادة 12: القانون المعمول به وتسوية النزاعات</h5>
              <p>
                تخضع هذه الشروط والأحكام لقوانين المملكة العربية السعودية. في
                حالة نشوء نزاع بين المستخدم و/أو وكيل البيع والشركة فيما يتعلق
                بهذه الشروط والأحكام، أو انتهاكها، أو إنهائها، أو تنفيذها، أو
                تفسيرها، أو صلاحيتها، أو استخدام الموقع، أو المنصة، أو الخدمات،
                يتم تسوية هذا النزاع وديًا من خلال التواصل مع فريق دعم العملاء
                الخاص بالمنصة.
              </p>
              <p>
                إذا لم يتم حل النزاع وديًا خلال 60 يومًا من تاريخ إخطار أحد
                الأطراف للطرف الآخر بالنزاع، يتفق الأطراف على أن يتم إحالة
                النزاع حصريًا إلى المحاكم المختصة في الرياض، وفقًا للقوانين
                المعمول بها في المملكة العربية السعودية.
              </p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AuctionTermsConditions;
