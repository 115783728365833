import React, { useState, useEffect } from "react";

import { Table } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiService from "../../../services/ApiService";
import { formatPrice } from "../../../utils/helper";
import Paginator from "../../../components/Paginator";

const ListPayment = () => {
  const { t } = useTranslation();
  const app_lang = localStorage.getItem("lang") || "en";
  const [payments, setPayments] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [apiData, setApiData] = useState({
    search: "",
    page: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    ListPayments();
  }, [apiData.page, apiData.search]);

  const ListPayments = async () => {
    try {
      setIsLoader(true);
      const response = await ApiService.request({
        method: "GET",
        url: `payments`, // Replace with your API endpoint
        params: apiData,
      });
      const data = response.data;
      if (data.status) {
        setPayments(data.data.payments);
        setPagination(data.data.pagination);
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };
  const handlePageChange = (event) => {
    setApiData((prev) => ({ ...prev, page: event }));
  };

  return (
    <div>
      <div className="real-estate-dashboard">
        <div className="d-flex justify-content-between align-items-center rounded estate-search-bar mt-5">
          {/* <InputGroup className="search-bar">
            <FormControl
              placeholder="Search by title"
              className="custom-width"
              value={apiData.search} onChange={e => setApiData({ ...apiData, search: e.target.value })}
            />
            <i className="bi bi-search estate-search"></i>
          </InputGroup> */}
          <h5 className="p-3">{t("payments")}</h5>
        </div>
      </div>
      <div className="table-estate p-3 mt-3 rounded bg-white">
        {isLoader && (
          <p className="text-center">
            <span className="spinner-border spinner-border-sm "></span> Loading
          </p>
        )}

        <Table responsive striped hover className="estate-table mt-3">
          <thead>
            <tr>
              <th>{t("trx_id")}</th>
              <th>{t("details")}</th>
              <th>{t("amount")}</th>
              <th>{t("status")}</th>
              <th>{t("date")}</th>
            </tr>
          </thead>
          <tbody>
            {payments.length > 0 ? (
              payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.paymentId}</td>
                  <td>
                    {payment.pay_for === "package" ? (
                      <span>
                        {payment.data["name"]} <br />
                        {payment.data["type"] == "no_ads"
                          ? payment.data["no_ads"]
                          : 1}{" "}
                        {t("ads")} <br />
                        {t("expiry")}:{" "}
                        {payment.data["expiry"] +
                          " " +
                          payment.data["expiry_type"]}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>
                    {" "}
                    {t("currency")} {formatPrice(payment.amount)}{" "}
                  </td>
                  <td> {payment.pay_status} </td>
                  <td className="ltr">{payment.created_at}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-dark text-center mt-2">
                  {t("no_data")}!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {pagination.total_pages > 1 && (
          <Paginator
            page={pagination.current_page}
            rows={pagination.per_page}
            totalRecords={pagination.total_records}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ListPayment;
