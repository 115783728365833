import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const BreadCrumb = ({ items = [] }) => {
  const { t } = useTranslation();
  const app_lang = localStorage.getItem("lang") || "en";

  return (
    <div className="estate-header">
      <div className="header-txt text-white">
        <p>
          {items.map((breadCrumbItem, index) => (
            <Fragment key={index}>
              {index !== 0 && (
                <i
                  className={`bi px-1 ${
                    app_lang === "en" ? "bi-chevron-right" : "bi-chevron-left"
                  }`}
                ></i>
              )}
              {items.length - 1 === index ? (
                t(breadCrumbItem.label)
              ) : (
                <NavLink to={breadCrumbItem.link} className="nav-link d-inline">
                  {t(breadCrumbItem.label)}
                </NavLink>
              )}
            </Fragment>
          ))}
        </p>
      </div>
    </div>
  );
};

export default BreadCrumb;
