import clsx from "clsx";

export const ListingCard = ({ items, heading }) => {
  return (
    <div className="remove-last-border-bottom">
      {heading && (
        <div className="row g-0">
          <div className="col p-0">
            <Heading heading={heading} />
          </div>
        </div>
      )}

      {items?.map((item, index) => (
        <div
          className={clsx(
            "row g-0 overflow-hidden",
            index === 0 && !heading && "rounded-top-3",
            items.length - 1 === index && "rounded-bottom-3 shadow"
          )}
          key={index}
        >
          <h6 className={clsx("col bg-secondary-subtle m-0 p-2 w-full")}>
            {item.name}
          </h6>
          <div
            className={clsx("col p-2 bg-light-gamma text-end border-bottom")}
          >
            {item.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export const ContentBlock = ({ heading, content, htmlContent, className }) => {
  const contentClasses = "p-2 bg-light-gamma m-0";

  return (
    <div className={clsx(className, "rounded-bottom-3 overflow-hidden shadow")}>
      <Heading heading={heading} />
      {typeof content === "string" || htmlContent ? (
        htmlContent ? (
          <p
            className={clsx(contentClasses)}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        ) : (
          <p className={clsx(contentClasses)}>{content}</p>
        )
      ) : (
        <>{content}</>
      )}
    </div>
  );
};

export const Heading = ({ heading }) => (
  <h5 className="bg-base m-0 p-2 rounded-top-3 text-white">{heading}</h5>
);
