import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FeedbackModal = ({ show, setShow, sendFeedback }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const emojis = [
    { label: "Dissatisfied", value: "bad", icon: "😡", color: "#e74c3c" },
    { label: "Neutral", value: "average", icon: "😐", color: "#f39c12" },
    { label: "Satisfied", value: "good", icon: "😊", color: "#2ecc71" },
    { label: "Very Satisfied", value: "best", icon: "😍", color: "#3498db" },
  ];

  const handleClose = () => {
    setShow(false);
  };

  const onCloseModal = () => {
    setLoading(false);
    setShow(false);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    sendFeedback({ feedback: text }, onCloseModal);
  };

  useEffect(() => {
    if (!show) {
      setText("");
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="px-4">
        <Modal.Title>{t("feedback")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <form onSubmit={submitHandler}>
          <div className="form-group">
            <Container className="text-center">
              <h4>How's the Auction?</h4>
              <Row className="mt-3 justify-content-center">
                {emojis.map((emoji, index) => (
                  <Col key={index} sm={2}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>{emoji.label}</Tooltip>
                      }
                    >
                      <Button
                        style={{
                          backgroundColor:
                            selected === emoji.value ? emoji.color : "#f8f9fa",
                          border: "none",
                          color: "black",
                        }}
                        className="p-3 border rounded-circle shadow-sm"
                        onClick={() => setSelected(emoji.value)}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = emoji.color)
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor =
                            selected === emoji.value ? emoji.color : "#f8f9fa")
                        }
                      >
                        <span style={{ fontSize: "1.5rem" }}>{emoji.icon}</span>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                ))}
              </Row>
            </Container>
            <div className="my-2">
              <label className="fw-bold" htmlFor="feedback">
                {t("feedback")} <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                style={{ height: "150px" }}
                value={text}
                onChange={(event) => setText(event.target.value)}
                required
              />
            </div>

            <div className="text-end mt-3">
              <button className="btn btn-base" disabled={loading}>
                {t("submit")}
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ms-1"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default FeedbackModal;
