import ButtonGroup from "react-bootstrap/ButtonGroup";
import RBDropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";

function Dropdown({ items, ...rest }) {
  const { t } = useTranslation();
  return (
    <DropdownButton as={ButtonGroup} {...rest} className="dropdown-blue-btn">
      {items?.map((item, index) => (
        <RBDropdown.Item
          as={"button"}
          key={index}
          eventKey={index}
          onClick={() => item.action?.()}
        >
          {t(item.label)}
        </RBDropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default Dropdown;
