import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const Button = ({ className, loading = false, disabled, ...props }) => {
  const { t } = useTranslation();
  return (
    <button
      {...props}
      className={clsx("btn btn-base", className)}
      disabled={loading || disabled}
    >
      {typeof props.children === "string" ? t(props.children) : props.children}
      {loading && (
        <span
          className="spinner-border spinner-border-sm ms-1"
          aria-hidden="true"
        ></span>
      )}
    </button>
  );
};
