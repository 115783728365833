import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from '../../../services/ApiService';
import { useTranslation } from "react-i18next";
// import ImageUpload from '../../../components/ImageUpload';
import "./agency.css"
import { Card, Form, Row, Col, Button } from "react-bootstrap";

const Agency = () => {
  const [banner, setBanner] = useState(null);
  const [logo, setLogo] = useState(null);
  const user= JSON.parse(localStorage.getItem('user'));

  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const [isLoader, setIsLoader] = useState(false);
  const [appLoader, setAppLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    name_ar: '',
  });

  useEffect(() => {
    getDropdownData();

    getSingleData();
  }, []);

  const getSingleData = async () => {
    setAppLoader(true);
    try {
      const response = await ApiService.request({
        method: 'GET',
        url: `agencies/${user?.agency_id}`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setFormData(data.data);

      } else {
        toast.error(data.message);
      }
      setAppLoader(false);
    } catch (error) {
      console.error(error);
    } finally {
      setAppLoader(false);
    }
  };
  
  const getDropdownData = async () => {
    try {
      const response = await ApiService.request({
        method: 'GET',
        url: `getDropdownData?type=agency`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setCategories(data.data.categories);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);

    try {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });
      data.append('logo',logo);
      data.append('banner',banner);

      const response = await ApiService.request({
        method: 'POST',
        url: `agencies/createOrUpdate/${user?.agency_id || ''}`, // Replace with your API endpoint
        data,
      });

      if (response.data.status) {
        toast.success(response.data.message);
        let user= JSON.parse(localStorage.getItem('user'));
        user.agency_id=response.data.data.id;
        localStorage.setItem('user', JSON.stringify(user));
        navigate('/dashboard?tab=agency');
      } else {
        toast.error(response.data.message);
      }
      setIsLoader(false);
    } catch (error) {
      console.error('Form submission failed:', error);
    } finally {
      setIsLoader(false);
    }
  };
  
  const handleImageUpload = (e, type) => {
    const file = e.target.files[0];
    if (type === "banner") setBanner(file);
    if (type === "logo") setLogo(file);
  };




  return (
    <>
      <div
        className="d-flex align-items-center p-2 mt-5"
        style={{
          borderRadius: "25px",
          backgroundColor: "#e9ecef",
          width: "13%",
        }}
      >
        <Button
          variant="dark"
          className="rounded-pill px-4 py-2"
          style={{ fontWeight: "500" }}
        >
          Agency 
        </Button>
        {/* <span className="mx-2">/</span>
        <span style={{ fontWeight: "400", color: "#000" }}>Managers</span> */}
      </div>
      {!appLoader ? (
        <div className="agency-main">
          <Card className="p-5">
            <h5>Agency Banner</h5>
            <div className="image-upload-section">
              <div
                className="image-placeholder"
                style={{
                  width: "100%",
                  height: "230px",
                  backgroundColor: "#1E3A5F",
                }}
              >
                {banner || formData.banner ? (
                  <img
                    src={banner ? URL.createObjectURL(banner): formData.banner}
                    alt="banner"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div className="text-center text-white p-5">
                    <i className="bi bi-upload fs-1"></i>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-start mt-2">
                <Button
                  className="upload-btn"
                  variant="outline-primary"
                  onClick={() => document.getElementById("bannerInput").click()}
                >
                  <i className="bi bi-upload"></i> Upload Image
                </Button>
                
              </div>
              <input
                id="bannerInput"
                type="file"
                accept="image/*, video/*"
                onChange={(e) => handleImageUpload(e, "banner")}
                hidden
              />
              <small className="text-muted">
                Recommended image size: 1200x400px, Max file size: 20MB, Allowed
                types: png, jpg, jpeg, webp, mp4, etc.
              </small>
            </div>
          </Card>

          <Card className="px-5 m-0">
            <h5>Agency Logo</h5>
            <div className="image-upload-section d-flex">
              <div
                className="image-placeholder"
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#1E3A5F",
                }}
              >
                {logo || formData.logo ? (
                  <img
                    src={logo ? URL.createObjectURL(logo): formData.logo}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div className="text-center text-white p-5">
                    <i className="bi bi-upload fs-1"></i>
                  </div>
                )}
              </div>
              <div className="d-flex flex-column justify-content-start mt-2">
                <div className="d-flex">
                  <Button
                    className="upload-btn-ii d-flex"
                    variant="outline-primary"
                    onClick={() => document.getElementById("logoInput").click()}
                  >
                    <i className="bi bi-upload mx-2"></i> Upload Image
                  </Button>
                  
                </div>
                <small className="text-muted mt-2 small">
                  Recommended image size: 390x330px, Max file size: 20MB, Allowed
                  types: png, jpg, jpeg etc.
                </small>
              </div>
              <input
                id="logoInput"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, "logo")}
                hidden
              />
            </div>
          </Card>

          <Form className="p-5" onSubmit={handleSubmit}>
            <Row className="mb-3">
            <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className="required fs-6 fw-semibold mb-2"> Name</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Agency Title"
                        required
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className="required fs-6 fw-semibold mb-2"> Name Arabic</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Agency Title Arabic"
                        required
                        name="name_ar"
                        value={formData.name_ar}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className="required fs-6 fw-semibold mb-2"> Email Address</label>
                      <input
                        type="email"
                        className="form-control form-control-solid"
                        placeholder="Enter Email Address"
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className=" fs-6 fw-semibold mb-2"> Phone</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Phone"

                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className=" fs-6 fw-semibold mb-2"> Website</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Website"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className="required fs-6 fw-semibold mb-2"> Category</label>
                      <select name="category_id" required  onChange={handleChange}  value={formData.category_id || ""}
                        className='form-select form-control-solid'>
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className=" fs-6 fw-semibold mb-2"> Slogon</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Slogon"
                        name="slogan"
                        value={formData.slogan}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className=" fs-6 fw-semibold mb-2"> Slogon Ar</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Slogon Arabic"
                        name="slogan_ar"
                        value={formData.slogan_ar}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className=" fs-6 fw-semibold mb-2"> Address</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 my-3 fv-plugins-icon-container">
                      <label className=" fs-6 fw-semibold mb-2"> Address Ar</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Address Arabic"
                        name="address_ar"
                        value={formData.address_ar}
                        onChange={handleChange}
                      />
                    </div>
            </Row>



            {/* Submit Button */}
            <Button variant="primary" type="submit" className="update-store-btn">
              Update Agency
              {isLoader && <span className="ms-2 spinner-grow  spinner-grow-sm"></span>}
            </Button>
          </Form>
        </div>
      ):(
        <div className="agency-main py-5">
          <p className="text-center"><span className="ms-2 spinner-border   spinner-border-sm "></span> Loading...  </p>
        </div>
      )}
    </>
  );
};

export default Agency;
