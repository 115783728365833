import React, { useState, useEffect } from "react";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import { Carousel } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Topbar from "../components/Top Bar/Topbar";
import PageLoader from "../components/PageLoader";
import ApiService from "../services/ApiService";
import Calculator from "../components/Calculator/Calculator";
import SocialMediaShare from "../components/SocialMediaShare";

import { Container, Row, Col } from "react-bootstrap";
import { momentWithTZ } from "../utils/helper";

const PropertyDetails = () => {
  const { t } = useTranslation();
  const app_lang = localStorage.getItem("lang") || "en";
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPageLoader, setPageLoader] = useState(false);
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleSelect = (selectedIndex) => {
    setSelectedIndex(selectedIndex);
  };
  const { slug } = useParams();
  const [property, setProperty] = useState([]);
  const [is_favourite, setIsFavourite] = useState(true);

  const [showPhone, setShowPhone] = useState(false);
  const [showWhatsapp, setShowWhatsapp] = useState(false);

  const toggleLastDigits = (type) => {
    if (type === "phone") {
      setShowPhone(!showPhone);
    } else {
      setShowWhatsapp(!showWhatsapp);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProperties();
  }, []);

  const getProperties = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: "GET",
        url: `getPropertyDetails`, // Replace with your API endpoint
        params: { property_id: slug },
      });
      const data = response.data;
      if (data.status) {
        setProperty(data.data);
        setIsFavourite(data.data.is_favourite);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const addRemoveFavourite = async () => {
    if (!user) {
      toast.error("You need to login first!");
      return;
    }
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `addRemoveFavourite`, // Replace with your API endpoint
        params: { property_id: property.id },
      });
      const data = response.data;
      if (data.status) {
        if (data.data == "added") {
          setIsFavourite(true);
        } else {
          setIsFavourite(false);
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const contactSeller = async (e) => {
    e.preventDefault();
    // if(!user){
    //     toast.error('You need to login first!');
    //     return;
    // }
    try {
      contactData["agency_id"] = property.agency.id;
      const response = await ApiService.request({
        method: "POST",
        url: `contactSeller`, // Replace with your API endpoint
        data: contactData,
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        setContactData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const truncateString = (str, num = 10) => {
    return str.length > num ? str.substring(0, num) + "..." : str;
  };

  return (
    <div className="main-property">
      {isPageLoader ? (
        <PageLoader />
      ) : (
        <div className="property-details-body">
          {/* Top Bar */}
          <Topbar />
          {/* Navbar */}
          <CustomNavbar />

          <div className="estate-header">
            <div className="header-txt text-white">
              <p>
                <NavLink to="/" className="nav-link d-inline">
                  {" "}
                  {t("home")}{" "}
                </NavLink>
                <i
                  className={`bi ${
                    app_lang === "en" ? "bi-chevron-right" : "bi-chevron-left"
                  }`}
                ></i>
                <NavLink to="/properties" className="nav-link d-inline">
                  {" "}
                  {t("real_estate")}{" "}
                </NavLink>
                <i
                  className={`bi ${
                    app_lang === "en" ? "bi-chevron-right" : "bi-chevron-left"
                  }`}
                ></i>{" "}
                {property.title}
              </p>
            </div>
          </div>
          <div className="container mb-5">
            <div className="row">
              <div className="col-md-8">
                <div className="badge-main d-flex mt-5">
                  <div className="badge1">
                    <span> {property.list_type}</span>
                  </div>
                  <div className="badge2 mx-2">
                    <span>{property.condition}</span>
                  </div>
                </div>
                <div className="heading my-4">
                  <h1>{property.title}</h1>
                </div>
                <div className="property-info">
                  {/* <i className="bi bi-arrow-repeat"></i> */}
                  <i className="bi bi-geo-alt-fill mx-2"></i>
                  <span>
                    {property.address}
                    <span className="mx-2">/</span>
                  </span>
                  <i className="bi bi-clock mx-1"></i>
                  <span>
                    {momentWithTZ(property.created_at).fromNow()}{" "}
                    <span className="mx-2">/</span>
                  </span>
                  <i className="bi bi-eye"></i>{" "}
                  <span>
                    {t("views")} : {property.views}
                  </span>
                </div>

                {property.media_files && (
                  <div className="mt-4">
                    <Carousel
                      activeIndex={selectedIndex}
                      onSelect={handleSelect}
                      className="main-carousel"
                    >
                      {property.media_files.map(
                        (file, index) =>
                          file.type.includes("image") && (
                            <Carousel.Item key={index}>
                              <img
                                className="d-block w-100"
                                src={file.file}
                                alt="property img"
                              />
                            </Carousel.Item>
                          )
                      )}
                    </Carousel>

                    <div className="thumbnail-container d-flex justify-content-center mt-3">
                      {property.media_files.map(
                        (file, index) =>
                          file.type.includes("image") && (
                            <img
                              key={index}
                              src={file.file}
                              alt="Thumbnail"
                              className={`rounded thumbnail ${
                                selectedIndex === index ? "selected" : ""
                              }`}
                              onClick={() => handleSelect(index)}
                            />
                          )
                      )}
                    </div>
                  </div>
                )}

                <div className="more-info-card mt-5 rounded shadow">
                  <h2>{t("overview")} </h2>

                  <div className="container">
                    <div className="row info-tab1 mt-4">
                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-tag shadow"></i>
                        <div className="id-num">
                          <p>{t("id_no")}</p>
                          <span>{property.id}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-house-gear shadow"></i>
                        <div className="id-num">
                          <p>{t("category")}</p>
                          <span>{property?.category?.name}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-car-front shadow"></i>
                        <div className="id-num">
                          <p>{t("parking")}</p>
                          <span>{property.parking}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-house shadow"></i>
                        <div className="id-num">
                          <p>{t("bedrooms")}</p>
                          <span>{property.bedrooms}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-house-door shadow"></i>
                        <div className="id-num">
                          <p>{t("bathrooms")}</p>
                          <span>{property.bathrooms}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-arrows-fullscreen shadow"></i>
                        <div className="id-num">
                          <p>{t("area")}</p>
                          <span>{property.area}</span>
                        </div>
                      </div>

                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-braces-asterisk shadow"></i>
                        <div className="id-num">
                          <p>{t("list_type")}</p>
                          <span>{property.list_type}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-geo-alt-fill shadow"></i>
                        <div className="id-num">
                          <p>{t("address")}</p>
                          {property.address && (
                            <span>{truncateString(property.address)}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-house-door shadow"></i>
                        <div className="id-num">
                          <p>{t("construction_condition")}</p>
                          <span>{property.condition}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 mb-5">
                        <i className="bi bi-house-door shadow"></i>
                        <div className="id-num">
                          <p>{t("construction_year")}</p>
                          <span>{property.construction_year}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="hr mt-2" />
                  <h3 className="my-4 this_list">{t("about_list")}</h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: property.description }}
                  ></p>
                  {property.video_files &&
                    property.video_files.map((video, index) => (
                      <video width="100%" controls>
                        <source src={video.file} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ))}
                  <hr className="hr" />

                  <Container className="my-5">
                    <h3 className="text-right">{t("features_amenities")}</h3>
                    {property.features && (
                      <Row className="mt-4">
                        {property.features.map((feature, index) => (
                          <Col
                            xs={6}
                            md={4}
                            className="d-flex flex-column align-items-start"
                            key={index}
                          >
                            <div className="d-flex align-items-center mb-3">
                              <i className="bi bi-check-circle-fill icon-col mx-2"></i>
                              <span className="check-txt">{feature}</span>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Container>
                  <hr />

                  <Container className="my-5">
                    <h3 className="text-right">{t("advertiser_info")}</h3>
                    {property.advertiser_info && (
                      <Row className="mt-4">
                        <Col xs={12} md={6}>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>{t("advertiser_number")}</th>
                                <td>
                                  {property.advertiser_info?.advertiserId}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("license_number")}</th>
                                <td>
                                  {property.advertiser_info?.adLicenseNumber}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("ad_issue_date")}</th>
                                <td>
                                  {property.advertiser_info?.creationDate}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("instrument_number")}</th>
                                <td>{property.advertiser_info?.deedNumber}</td>
                              </tr>
                              <tr>
                                <th>{t("advertiser_name")}</th>
                                <td>
                                  {property.advertiser_info?.advertiserName}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("phone_number")}</th>
                                <td>{property.advertiser_info?.phoneNumber}</td>
                              </tr>
                              <tr>
                                <th>
                                  {t("marketing_brokerage_license_number")}
                                </th>
                                <td>
                                  {
                                    property.advertiser_info
                                      ?.brokerageAndMarketingLicenseNumber
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th>{t("restriction")}</th>
                                <td>
                                  {property.advertiser_info?.isConstrained}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("mortgaged")}</th>
                                <td>{property.advertiser_info?.isPawned}</td>
                              </tr>
                              <tr>
                                <th>{t("position")}</th>
                                <td>{property.advertiser_info?.isHalted}</td>
                              </tr>
                              <tr>
                                <th>{t("property_age")}</th>
                                <td>{property.advertiser_info?.propertyAge}</td>
                              </tr>
                              <tr>
                                <th>{t("street_view")}</th>
                                <td>{property.advertiser_info?.streetWidth}</td>
                              </tr>
                              <tr>
                                <th>{t("area")}</th>
                                <td>
                                  {property.advertiser_info?.propertyArea}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("property_price")}</th>
                                <td>
                                  {property.advertiser_info?.propertyPrice}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("property_type")}</th>
                                <td>
                                  {property.advertiser_info?.propertyType}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("no_rooms")}</th>
                                <td>
                                  {property.advertiser_info?.numberOfRooms}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("ad_type")}</th>
                                <td>
                                  {property.advertiser_info?.advertisementType}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("region")}</th>
                                <td>
                                  {property.advertiser_info?.location?.region}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("ad_end_date")}</th>
                                <td>{property.advertiser_info?.endDate}</td>
                              </tr>

                              <tr>
                                <th>{t("saudi_building_code")}</th>
                                <td>
                                  {property.advertiser_info
                                    ?.complianceWithTheSaudiBuildingCode
                                    ? t("yes")
                                    : t("no")}
                                </td>
                              </tr>

                              <tr>
                                <th>{t("testament_property")}</th>
                                <td>
                                  {property.advertiser_info?.isTestment
                                    ? t("yes")
                                    : t("no")}
                                </td>
                              </tr>

                              <tr>
                                <th>{t("main_land_use_type")}</th>
                                <td>
                                  {property.advertiser_info
                                    ?.mainLandUseTypeName || t("none")}
                                </td>
                              </tr>

                              <tr>
                                <th>{t("red_zone_type")}</th>
                                <td>
                                  {property.advertiser_info?.redZoneTypeName ||
                                    t("none")}
                                </td>
                              </tr>

                              <tr>
                                <th>{t("title_deed_type")}</th>
                                <td>
                                  {property.advertiser_info?.titleDeedTypeName}
                                </td>
                              </tr>

                              <tr>
                                <th>{t("notes")}</th>
                                <td>
                                  {property.advertiser_info?.notes || t("none")}
                                </td>
                              </tr>
                            </thead>
                          </table>
                        </Col>
                        <Col xs={12} md={6}>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>{t("city")}</th>
                                <td>
                                  {property.advertiser_info?.location?.city}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("neighborhood")}</th>
                                <td>
                                  {property.advertiser_info?.location?.district}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("street")}</th>
                                <td>
                                  {property.advertiser_info?.location?.street}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("mail")}</th>
                                <td>
                                  {
                                    property.advertiser_info?.location
                                      ?.postalCode
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th>{t("building_no")}</th>
                                <td>
                                  {
                                    property.advertiser_info?.location
                                      ?.buildingNumber
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th>{t("coordinates")}</th>
                                <td>
                                  Lat:{" "}
                                  {property.advertiser_info?.location?.latitude}
                                  , Lng:{" "}
                                  {
                                    property.advertiser_info?.location
                                      ?.longitude
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th>{t("property_facade")}</th>
                                <td>
                                  {property.advertiser_info?.propertyFace}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("property_usage")}</th>
                                <td>
                                  {property.advertiser_info?.propertyUsages &&
                                    property.advertiser_info?.propertyUsages.map(
                                      (usage) => (
                                        <span className="badge bg-dark">
                                          {" "}
                                          {usage}
                                        </span>
                                      )
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("property_services")}</th>
                                <td>
                                  {property.advertiser_info
                                    ?.propertyUtilities &&
                                    property.advertiser_info?.propertyUtilities.map(
                                      (utl) => (
                                        <span className="badge bg-dark">
                                          {" "}
                                          {utl}
                                        </span>
                                      )
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("plan_number")}</th>
                                <td>{property.advertiser_info?.planNumber}</td>
                              </tr>
                              <tr>
                                <th>{t("part_number")}</th>
                                <td>{property.advertiser_info?.landNumber}</td>
                              </tr>
                              <tr>
                                <th>{t("location_description")}</th>
                                <td>
                                  {
                                    property.advertiser_info
                                      ?.locationDescriptionOnMOJDeed
                                  }
                                </td>
                              </tr>

                              <tr>
                                <th>{t("guarantees_and_duration")}</th>
                                <td>
                                  {
                                    property.advertiser_info
                                      ?.guaranteesAndTheirDuration
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th>{t("obligations")}</th>
                                <td>
                                  {
                                    property.advertiser_info
                                      ?.obligationsOnTheProperty
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th>{t("ad_channels")}</th>
                                <td>
                                  {property.advertiser_info?.channels?.map(
                                    (channel, i) => (
                                      <span
                                        key={i}
                                        className="badge bg-secondary me-1"
                                      >
                                        {channel}
                                      </span>
                                    )
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <th>{t("property_borders")}</th>
                                <td>
                                  <div>
                                    <strong>{t("north")}:</strong>{" "}
                                    {property.advertiser_info?.borders
                                      ?.northLimitName || "-"}{" "}
                                    (
                                    {property.advertiser_info?.borders
                                      ?.northLimitDescription || "-"}
                                    )
                                  </div>
                                  <div>
                                    <strong>{t("south")}:</strong>{" "}
                                    {property.advertiser_info?.borders
                                      ?.southLimitName || "-"}{" "}
                                    (
                                    {property.advertiser_info?.borders
                                      ?.southLimitDescription || "-"}
                                    )
                                  </div>
                                  <div>
                                    <strong>{t("east")}:</strong>{" "}
                                    {property.advertiser_info?.borders
                                      ?.eastLimitName || "-"}{" "}
                                    (
                                    {property.advertiser_info?.borders
                                      ?.eastLimitDescription || "-"}
                                    )
                                  </div>
                                  <div>
                                    <strong>{t("west")}:</strong>{" "}
                                    {property.advertiser_info?.borders
                                      ?.westLimitName || "-"}{" "}
                                    (
                                    {property.advertiser_info?.borders
                                      ?.westLimitDescription || "-"}
                                    )
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>{t("sources")}</th>
                                <td>{property.advertiser_info?.adSource}</td>
                              </tr>
                              <tr>
                                <th>{t("license_link")}</th>
                                <td>
                                  <a
                                    href={
                                      property.advertiser_info?.adLicenseUrl
                                    }
                                    target="_blank"
                                    title={
                                      property.advertiser_info?.adLicenseUrl
                                    }
                                  >
                                    {property.advertiser_info?.adLicenseUrl?.slice(
                                      0,
                                      35
                                    )}
                                    {property.advertiser_info?.adLicenseUrl
                                      ?.length > 35
                                      ? "..."
                                      : ""}
                                  </a>
                                </td>
                              </tr>
                            </thead>
                          </table>
                        </Col>
                      </Row>
                    )}
                  </Container>
                  <hr />

                  {/* <Container className="my-5">
                  <h3 className="text-right">{t('owner_info')}</h3>
                  {property.owner_info  &&
                  <Row className="mt-4">
                      <Col  xs={12}  md={12}  >
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>{t('owner_type')}</th>
                              <td>{property.owner_info?.owner_type}</td>
                            </tr>
                          </thead>

                            {property.owner_info?.owner_type=== 'Company' &&
                              <tbody>
                                <tr>
                                  <th>{t('deed_no')}</th>
                                  <td>{property.owner_info?.company.deed_no}</td>
                                </tr>
                                <tr>
                                  <th>{t('unified_no')}</th>
                                  <td>{property.owner_info?.company.unified_no}</td>
                                </tr>
                                <tr>
                                  <th>{t('agent_id_no')}</th>
                                  <td>{property.owner_info?.company.agent_id_no}</td>
                                </tr>
                                <tr>
                                  <th>{t('agency_no')}</th>
                                  <td>{property.owner_info?.company.agency_no}</td>
                                </tr>
                                <tr>
                                  <th>{t('agent_dob')}</th>
                                  <td>{property.owner_info?.company.agent_dob}</td>
                                </tr>
                                <tr>
                                  <th>{t('agent_mobile')}</th>
                                  <td>{property.owner_info?.company.agent_mobile}</td>
                                </tr>
                              </tbody>
                            }
                            {property.owner_info?.owner_type=== 'Owner' &&
                              <tbody>
                                <tr>
                                  <th>{t('deed_no')}</th>
                                  <td>{property.owner_info?.owner.deed_no}</td>
                                </tr>
                                <tr>
                                  <th>{t('owner_mobile')}</th>
                                  <td>{property.owner_info?.owner.owner_mobile}</td>
                                </tr>
                                <tr>
                                  <th>{t('owner_id_no')}</th>
                                  <td>{property.owner_info?.owner.owner_id_no}</td>
                                </tr>
                                <tr>
                                  <th>{t('owner_dob')}</th>
                                  <td>{property.owner_info?.owner.owner_dob}</td>
                                </tr>
                              </tbody>
                            }
                            {property.owner_info?.owner_type=== 'Multi Owner' &&
                              <tbody>
                                 <tr>
                                  <th>{t('deed_no')}</th>
                                  <td>{property.owner_info?.multiowner.deed_no}</td>
                                </tr>
                                <tr>
                                  <th>{t('id_one_owner')}</th>
                                  <td>{property.owner_info?.multiowner.id_one_owner}</td>
                                </tr>
                                <tr>
                                  <th>{t('agent_id_no')}</th>
                                  <td>{property.owner_info?.multiowner.agent_id_no}</td>
                                </tr>
                                <tr>
                                  <th>{t('agent_dob')}</th>
                                  <td>{property.owner_info?.multiowner.agent_dob}</td>
                                </tr>
                                <tr>
                                  <th>{t('agent_mobile')}</th>
                                  <td>{property.owner_info?.multiowner.agent_mobile}</td>
                                </tr>
                               
                              </tbody>
                            }
                     
                        </table>
                      </Col>
                      
                  </Row>
                  }
                </Container>
                <hr /> */}

                  <div
                    style={{
                      width: "100%",
                      height: "500px",
                      overflow: "hidden",
                    }}
                  >
                    <h3 className="my-3">{t("site_map")}</h3>
                    <iframe
                      src={`https://www.google.com/maps?q=${property.lat},${property.lng}&hl=es;z=14&output=embed`}
                      width="100%"
                      height="80%"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title={property.address}
                    ></iframe>
                  </div>
                  <hr />
                </div>
              </div>

              <div className="col-md-4 mt-5">
                <div className="main-right">
                  {property.pricing !== "disabled" && (
                    <div>
                      <h4>
                        {t("currency")} {property.price}
                        {property.pricing === "range" && (
                          <span>
                            {" "}
                            - {t("currency")} {property.max_price}
                          </span>
                        )}
                      </h4>
                      <p>({property.price_type})</p>
                    </div>
                  )}

                  <div className="d-flex justify-content-flex-end align-items-center mt-5 icons-ac">
                    {/* Action Buttons */}
                    <div className="actoins-icons">
                      {/* <i className="bi bi-bug "></i> */}
                      <SocialMediaShare />
                      <i
                        className={`bi ${
                          is_favourite ? "bi-heart-fill" : "bi-heart"
                        }`}
                        onClick={() => addRemoveFavourite()}
                      ></i>
                      <i
                        className="bi bi-printer"
                        onClick={() => window.print()}
                      ></i>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <h4 className="fw-normal">{t("contact_listing")}</h4>
                      <div className="d-flex align-items-center mb-3">
                        <img
                          src={property?.agency?.logo}
                          alt="Owner"
                          className="rounded-circle mr-3"
                        />
                        <div className="right-form">
                          <h5 className="fw-light my-3">
                            {property.agency?.name}
                          </h5>
                          {property.whatsapp && (
                            <p className="m-0 d-flex">
                              <a
                                href={"tel:" + property.phone}
                                className="nav-link"
                              >
                                <i className="bi bi-telephone-fill me-2"></i>{" "}
                                <span>
                                  {showPhone && property.phone
                                    ? property.phone
                                    : property.phone.substring(0, 5) + "*****"}
                                  {/* {property.agency?.phone}  */}
                                </span>
                              </a>{" "}
                              <span
                                onClick={() => toggleLastDigits("phone")}
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                {showPhone ? "(Hide)" : "(Show)"}{" "}
                              </span>
                            </p>
                          )}
                          {property.whatsapp && (
                            <p className="m-0 d-flex">
                              <a
                                href={"https://wa.me/" + property.whatsapp}
                                className="nav-link"
                              >
                                <i className="bi bi-telephone-fill me-2"></i>{" "}
                                <span>
                                  {showWhatsapp && property.whatsapp
                                    ? property.whatsapp
                                    : property.whatsapp.substring(0, 5) +
                                      "*****"}
                                  {/* {property.agency?.phone}  */}
                                </span>
                              </a>{" "}
                              <span
                                onClick={() => toggleLastDigits("whatsapp")}
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                {showWhatsapp ? "(Hide)" : "(Show)"}{" "}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>

                      {/* Contact Form */}
                      <form onSubmit={contactSeller}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={contactData.name}
                            onChange={handleChange}
                            placeholder={t("name")}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            value={contactData.email}
                            onChange={handleChange}
                            className="form-control"
                            id="email"
                            placeholder={t("email")}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="tel"
                            name="phone"
                            value={contactData.phone}
                            onChange={handleChange}
                            className="form-control"
                            id="phone"
                            placeholder={t("phone")}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="message"
                            name="message"
                            value={contactData.message}
                            onChange={handleChange}
                            rows="3"
                            placeholder={t("message")}
                            required
                          ></textarea>
                        </div>
                        <button
                          type="submit"
                          className="sendButton btn-block shadow rounded"
                        >
                          {t("send_message")}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="second-right rounded p-0">
                  {/* <h3>Recently added</h3> */}
                  {/* <img src={test_property} alt="" className="rounded" />
                <div className="details-props">
                  <p className="text-below-image">chalet</p>
                  <h5>Test Property</h5>
                  <p className="location">
                    <i className="bi bi-geo-alt"></i>
                    <span>Riyadh Region, Saudi Arabia</span>
                  </p>
                  <span>Rs 200 (Negotiable)</span>
                </div> */}
                </div>
                <Calculator />
              </div>
            </div>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default PropertyDetails;
