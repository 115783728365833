import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Topbar from "../components/Top Bar/Topbar";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import {
  NavLink,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import PageLoader from "../components/PageLoader";
import "../assets/css/payment-methods.css";
import success from "../assets/img/success.jpg";
import failed from "../assets/img/failed.jpg";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";

const PaymentStatus = () => {
  const { t } = useTranslation();
  const [isPageLoader, setPageLoader] = useState(false);
  const navigate = useNavigate();

  const app_lang = localStorage.getItem("lang") || "en";
  const invoice_id = localStorage.getItem("invoice_id") || null;
  const { status } = useParams();
  const [searchParams] = useSearchParams(); // For query parameters like "?status=failed"
  const package_id = searchParams.get("package_id");
  const redirectUrl = searchParams.get("redirect_url");
  redirectUrl && window.open(redirectUrl, "_self");

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (status=="success") {
    !redirectUrl && purchasePackage();
    // }
  }, []);

  const purchasePackage = async () => {
    try {
      // setPageLoader(true);
      const response = await ApiService.request({
        method: "POST",
        url: `/purchasePackage`,
        data: { package_id, invoice_id },
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        let user = JSON.parse(localStorage.getItem("user"));
        user.subscription_status = true;
        localStorage.setItem("user", JSON.stringify(user));
      } else {
        toast.error(data.message);
        navigate("/payment/failed");
      }
      localStorage.removeItem("invoice_id");
    } catch (error) {
      setPageLoader(false);
    }
  };

  return (
    <div>
      {isPageLoader ? (
        <PageLoader />
      ) : (
        <div className="main-en">
          <Topbar />
          <CustomNavbar />
          {/* Header */}
          <div className="estate-header">
            <div className="header-txt text-white">
              <p>
                <NavLink to="/" className="nav-link d-inline">
                  {" "}
                  {t("home")}{" "}
                </NavLink>
                <i
                  className={`bi ${
                    app_lang === "en" ? "bi-chevron-right" : "bi-chevron-left"
                  }`}
                ></i>{" "}
                {t("payment_status")}
              </p>
            </div>
          </div>

          {/* Package Body */}
          <div className="container">
            <div className="text-center mt-5">
              {status == "success" ? (
                <h1 className="text-success">{t("Payment Successful")}!</h1>
              ) : (
                <h1 className="text-danger">{t("Payment Failed")}!</h1>
              )}
            </div>
            <div className="row mb-5">
              <div className="col-md-8 mx-auto">
                <div className="bg-white rounded p-4 border text-center">
                  {status == "success" ? (
                    <div className="">
                      <img
                        src={success} // Use a default image if not available
                        alt="success"
                        className="w-50 text-center"
                      />
                      <p className="text-dark lead">{t("success_msg")}!</p>
                      <NavLink
                        to="/dashboard?tab=add-property"
                        className="btn btn-success"
                      >
                        {t("success_btn")}
                      </NavLink>
                    </div>
                  ) : (
                    <div className="">
                      <img
                        src={failed} // Use a default image if not available
                        alt="failed"
                        className="w-50 text-center"
                      />
                      <p className="text-dark lead">{t("failed_msg")}.</p>
                      <NavLink to="/packages" className="btn btn-warning">
                        {t("failed_btn")}
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </div>
  );
};

export default PaymentStatus;
