import React, { useState, useEffect } from "react";

import { Table } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiService from "../../services/ApiService";
import { Button } from "../Buttons";

const ChatList = () => {
  const { t } = useTranslation();
  const [properties, setProperties] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [apiData, setApiData] = useState({
    search: "",
    page: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getTickets();
  }, [apiData.page, apiData.search]);

  const getTickets = async () => {
    try {
      setIsLoader(true);
      const response = await ApiService.request({
        method: "GET",
        url: `my-tickets`, // Replace with your API endpoint
        params: apiData,
      });
      const data = response.data;
      if (data.status) {
        setProperties(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };

  const handleResolve = async (id, setLoading) => {
    setLoading(true);
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `update-ticket-status`, // Replace with your API endpoint
        data: { ticket_id: id, status: "solved" },
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        getTickets();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="real-estate-dashboard">
        <div className="d-flex justify-content-between align-items-center rounded estate-search-bar mt-5">
          <h3 className="p-3">{t("tickets")}</h3>
        </div>
      </div>
      <div className="table-estate p-3 mt-3 rounded bg-white">
        {isLoader && (
          <p className="text-center">
            <span className="spinner-border spinner-border-sm "></span> Loading
          </p>
        )}

        {!!properties?.length && (
          <Table responsive striped hover className="estate-table mt-3">
            <thead>
              <tr>
                <th>{t("subject")}</th>
                <th>{t("description")}</th>
                <th>{t("status")}</th>
              </tr>
            </thead>
            <tbody>
              {properties?.length > 0 ? (
                properties.map((item) => (
                  <tr key={item.id}>
                    <td>{item.subject}</td>
                    <td>{item.description}</td>
                    <td>
                      {item.status === "pending" ? (
                        <ResolveButton
                          handleResolve={handleResolve}
                          item={item}
                        />
                      ) : (
                        t(item.status)
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-dark text-center mt-2">
                    {t("no_data")}!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

const ResolveButton = ({ handleResolve, item }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Button
      loading={loading}
      onClick={() => handleResolve(item.id, setLoading)}
    >
      resolve
    </Button>
  );
};

export default ChatList;
