import React, { useEffect, useRef, useState } from "react";
import { Button } from "../Buttons";
import ApiService from "../../services/ApiService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const ChatBox = ({
  chatData = {},
  setChatData,
  ticketId,
  onClose = () => {},
  getChat,
}) => {
  const { t } = useTranslation();
  const [input, setInput] = useState("");
  const scrollBox = useRef(null);
  const [resolveLoading, setResolveLoading] = useState(false);

  useEffect(() => {
    getChat();
  }, []);

  useEffect(() => {
    if (scrollBox.current) {
      scrollBox.current.scrollTo({
        top: scrollBox.current.scrollHeight, // Scroll to the bottom
        behavior: "smooth", // Smooth scrolling
      });
    }
  }, [chatData]);

  const sendNewMessage = async (newData) => {
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `ticket-conversation`, // Replace with your API endpoint
        data: {
          ticket_id: ticketId,
          message: newData.message,
        },
      });

      const data = response.data;

      if (data.status) {
        // setChatData(data.data.conversation);
      }
    } catch (error) {
    } finally {
      // next();
    }
  };

  const handleMessage = () => {
    if (input.trim() === "") return;
    const newMessage = {
      message_by: "user",
      message: input,
      message_at: new Date().toISOString(),
    };

    setChatData((ps) => ({
      ...ps,
      conversation: [...(ps.conversation || []), newMessage],
    }));
    setInput("");

    sendNewMessage(newMessage);
  };

  const handleResolve = async () => {
    setResolveLoading(true);
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `update-ticket-status`, // Replace with your API endpoint
        data: { ticket_id: ticketId, status: "solved" },
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        getChat();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setResolveLoading(false);
    }
  };

  return (
    <div
      className="container position-fixed bottom-0 end-0 mb-3 me-3"
      style={{ maxWidth: "400px", zIndex: 1050 }}
    >
      <div className="card">
        <div className="card-header bg-base text-white text-center d-flex justify-content-between fs-4">
          <span></span>
          <span>Chat</span>
          <i className="bi bi-x-lg cursor-pointer" onClick={onClose}></i>
        </div>

        <div className="row g-0">
          <div className="col-4 p-2 text-start bg-light-beta border-bottom">
            <b className="">{t("subject")}</b>
          </div>
          <div className="col-8 p-2 text-start border-bottom">
            {chatData?.subject}
          </div>
        </div>
        <div className="row g-0">
          <div className="col-4 p-2 text-start bg-light-beta">
            <b className="">{t("description")}</b>
          </div>
          <div className="col-8 p-2 text-start text-truncate">
            {chatData?.description}
          </div>
        </div>
        {chatData.status === "pending" && (
          <Button
            className={"mt-2"}
            onClick={handleResolve}
            loading={resolveLoading}
          >
            {t("resolve")}
          </Button>
        )}
        {chatData.status === "solved" && (
          <div className="btn-light p-2">
            {t("solved")}{" "}
            <i className="bi bi-check-circle" style={{ color: "green" }}></i>
          </div>
        )}

        {/* <ListingCard
          items={[
            { name: t("subject"), value: chatData?.subject },
            { name: t("description"), value: chatData?.description },
          ]}
        /> */}

        {/* <div className="bg-black text-white d-flex gap-3 px-1 border-bottom">
          <span>{t("subject")}:-</span>
          <span className="text-truncate">{chatData?.subject}</span>
        </div>
        <div className="bg-black text-white d-flex gap-3 px-1">
          <span>{t("description")}:-</span>
          <span className="text-truncate">{chatData?.description}</span>
        </div> */}

        <div
          ref={scrollBox}
          className="card-body bg-body-secondary"
          style={{ height: "300px", overflowY: "auto" }}
        >
          {chatData.conversation?.map((item, index) => (
            <div
              key={index}
              className={`d-flex ${
                item.message_by === "user"
                  ? "justify-content-end"
                  : "justify-content-start"
              } mb-2`}
            >
              <div
                className={`p-2 rounded fs-6 ${
                  item.message_by === "user"
                    ? "bg-success text-white"
                    : "bg-light"
                }`}
              >
                {item.message}
              </div>
            </div>
          ))}
        </div>
        {chatData?.status !== "solved" && (
          <div className="card-footer">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleMessage()}
              />
              <Button className="" onClick={handleMessage}>
                Send
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
