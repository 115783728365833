import momentHijri from "moment-hijri";
const lang = localStorage.getItem("lang") || "en";
momentHijri.locale(lang);
if (lang === "ar") {
  momentHijri.updateLocale("ar", {
    months: [
      "محرم",
      "صفر",
      "ربيع الأول",
      "ربيع الآخر",
      "جمادى الأولى",
      "جمادى الآخرة",
      "رجب",
      "شعبان",
      "رمضان",
      "شوال",
      "ذو القعدة",
      "ذو الحجة",
    ],
  });
}
export default momentHijri;
