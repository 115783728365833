import { useEffect, useState } from "react";
import { Accordion, Button, Modal, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PiCashRegister, PiHandCoinsFill } from "react-icons/pi";
import { RiLuggageDepositLine } from "react-icons/ri";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";

const MenuModal = ({ show, setShow, type }) => {
  const handleClose = () => {
    setShow(false);
  };

  const getComponent = () => {
    switch (type) {
      case "HOW_IT_WORKS":
        return <HowItWorks />;
      case "FAQS":
        return <Faqs />;
      default:
        break;
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      {getComponent()}
    </Modal>
  );
};

export default MenuModal;

const HowItWorks = () => {
  const { t } = useTranslation();

  const Step = ({ index, title, icon }) => {
    return (
      <div className="d-flex flex-sm-column gap-2">
        <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2">
          <div className="p-2 rounded-circle bg-base">{icon}</div>

          {/* do not show divider for last step item */}
          {index !== 3 && (
            <>
              <hr className="flex-grow-1 d-none d-sm-block" />
              <div
                class="vr d-sm-none"
                style={{ height: "40px", alignSelf: "center" }}
              ></div>
            </>
          )}
        </div>
        <Stack>
          <span>
            {t("step")} {index}
          </span>
          <b>{title}</b>
        </Stack>
      </div>
    );
  };

  return (
    <>
      <Modal.Header className="px-4" closeButton>
        <Modal.Title>{t("how_it_works")}?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div>
          {/* demo video */}
          <iframe
            title="How it works"
            width="100%"
            height="300"
            // style={{ height: "" }}
            src="https://www.youtube.com/embed/tgbNymZ7vqY"
          />

          {/* steps */}
          <div>
            <div className="row my-3 gap-1 gap-sm-0">
              <div className="col-12 col-sm-4">
                <Step
                  index={1}
                  title={t("register_auction")}
                  icon={<PiCashRegister size={24} color="#fff" />}
                />
              </div>
              <div className="col-12 col-sm-4">
                <Step
                  index={2}
                  title={t("pay_bid_deposit")}
                  icon={<RiLuggageDepositLine size={24} color="#fff" />}
                />
              </div>
              <div className="col-12 col-sm-4">
                <Step
                  index={3}
                  title={t("bid_win")}
                  icon={<PiHandCoinsFill size={24} color="#fff" />}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

const Faqs = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const ifLngEng = (localStorage.getItem("lang") || "en") === "en"; // Default to 'en' if not set

  const getFaqs = async () => {
    setLoading(true);
    try {
      const response = await ApiService.request({
        method: "GET",
        url: `faqs`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setFaqList(data.data);
        toast.success(data.message);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <>
      <Modal.Header className="px-4" closeButton>
        <Modal.Title>{t("faq")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Accordion defaultActiveKey="0">
          {faqList?.map((item) => (
            <Accordion.Item key={item.id} eventKey={item.id}>
              <Accordion.Header>
                {ifLngEng
                  ? item.questions
                  : item.questions_ar || item.questions}
              </Accordion.Header>
              <Accordion.Body>
                {ifLngEng ? item.answers : item.answers_ar || item.answers}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
    </>
  );
};