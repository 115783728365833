// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    backend: {
      loadPath: '/locales/{{lng}}.json'
    },
    interpolation: {
      escapeValue: false
    }
  });

  const storedLanguage = localStorage.getItem('lang');
  if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
  }

  i18n.on('languageChanged', (lng) => {
      document.body.className = lng;
  });
  
  
export default i18n;
