import { useMemo } from "react";

export const YoutubeVideo = ({ url }) => {
  const videoId = useMemo(() => {
    if (!url) return null;
    function getYouTubeVideoId() {
      const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|shorts\/|v\/|.+\?v=)|youtu\.be\/)([\w-]{11})/;
      const match = url.match(regex);
      return match ? match[1] : null;
    }

    return getYouTubeVideoId();
  }, [url]);

  return (
    <iframe
      className="rounded-3"
      style={{ width: "100%", height: "400px" }}
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};
