import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";

import Topbar from "../components/Top Bar/Topbar";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import { NavLink ,useParams } from 'react-router-dom';
import PageLoader from "../components/PageLoader";
import ApiService from '../services/ApiService';
import { toast } from "react-toastify";
import '../assets/css/payment-methods.css'



const BuyPackage = () => {
  const { t } = useTranslation();
  const [isPageLoader, setPageLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [pay_methods, setPayMethods] = useState([]);
  const [pkg, setPackage] = useState([]);
  const [paymentURL, setPaymentURL] = useState(null);
  const user= JSON.parse(localStorage.getItem('user'));
  
  const [selectedMethod, setSelectedMethod] = useState(null);

  const app_lang=localStorage.getItem('lang') || 'en';
  const { id } = useParams(); 

  useEffect(() => {
    window.scrollTo(0, 0);
    getPackageDetails();
  }, []);

  useEffect(() => {
    if (pkg && pkg.price) {
      initiatePayment(); 
    }
  }, [pkg]);
  
  const getPackageDetails = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `getPackageDetails/`+id, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setPackage(data.data);
      } else {
        toast.error(data.message);
        setPageLoader(false);
      }
    } catch (error) {
      setPageLoader(false);
    }
  };

  
  const initiatePayment = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'POST',
        url: `initiatePayment`, // Replace with your API endpoint
        data: {amount: pkg.price}
      });
      const data = response.data;
      if (data.status) {
        setPayMethods(data.data.paymentMethods);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const handlePayment = async () => {
    if (!selectedMethod) {
      alert('Please select a payment method');
      return;
    }
    setIsLoader(true);
    const response = await ApiService.request({
      method: 'POST',
      url: `executePayment`, // Replace with your API endpoint
      data: {
        paymentMethodId: selectedMethod.PaymentMethodId,
        amount:pkg.price,
        customerName:user.name,
        customerEmail:user.email,
        package_id:id,
      }
    });
    const data= response.data;
    setIsLoader(false);
    setPaymentURL(data.data.payment_url);
    localStorage.setItem('invoice_id',data.data.invoice_id);
    window.location.href = data.data.payment_url;
  };

  return (
    <div>
      {isPageLoader ? (
        <PageLoader />
      ):(
      <div className="main-en">
        <Topbar />
        <CustomNavbar />
        {/* Header */}
        <div className="estate-header">
          <div className="header-txt text-white">
            <p>
            <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
            <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('purchase_package')}
            </p>
          </div>
        </div>

        {/* Package Body */}
        <div className="container">
          <div className="text-center my-5">
            <h1>{t('purchase_package')}</h1>
          </div>
          <div className="row mb-5">

           <div className="col-md-8">
            <div className="card p-4 border">
              {paymentURL ? (
                  <iframe
                      src={paymentURL}
                      width="100%"
                      height="500px"
                      title="MyFatoorah Payment"
                  ></iframe>
              ):(
                <div className="payment-methods-container">
                  <h4 className="text-center">{t('select_pay_method')}</h4>
                  <div className="payment-methods-list">
                    {pay_methods.map((method) => (
                      <div
                        key={method.PaymentMethodId}
                        className={`payment-method-card ${
                          selectedMethod?.PaymentMethodId === method.PaymentMethodId
                            ? 'selected'
                            : ''
                        }`}
                        onClick={() => setSelectedMethod(method)}
                      >
                        <img
                          src={method.ImageUrl || 'default-image.jpg'} // Use a default image if not available
                          alt={method.PaymentMethodEn}
                          className="payment-method-icon"
                        />
                        <h3>{method.PaymentMethodEn}</h3>
                      </div>
                    ))}
                  </div>
                  <button
                    className="continue-btn "
                    onClick={handlePayment}
                    disabled={!selectedMethod || isLoader}
                  >
                    Continue {isLoader && <span className="ms-2 spinner-border "></span>}
                  </button>
                </div>
              )}
            </div>
              
            </div>
            <div className="col-md-4">
              <div className="card p-4 border">
                <h5>{t('package_details')}</h5>
                <hr />
                <h3>{pkg.name}</h3> 
                <h2>{t('currency')} {pkg.price} <sub>\ {t('ads')}</sub></h2>
                <h4>
                      {pkg.type === 'per_ad' ?(
                        <span className="">{t('one_time_package')}</span>
                      ):(
                        <span className="">{pkg.no_ads} {t('license_package')}</span>
                      )} 
                </h4>
                <p>{pkg.description}</p>
              </div>

            </div>
           
          
          </div>
        </div>

      

        <Footer />
      </div>
      )}
    </div>
  );
};

export default BuyPackage;
