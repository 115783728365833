import { useTranslation } from "react-i18next";
import { momentWithTZ } from "../../utils/helper";

export const LiveAuction = ({ text, iconSize }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center align-items-center gap-2 justify-content-start">
      <span role="status">{t(text)}</span>
      <LiveDot iconSize={iconSize} />
    </div>
  );
};

export const LiveDot = ({ iconSize = "16px" }) => (
  <span
    className="live-dot d-inline-block"
    style={{ height: iconSize, width: iconSize }}
  ></span>
);

export const AuctionEnded = ({ data, ui }) => {
  const { t } = useTranslation();
  if (!data) return null;
  if (ui) return ui;
  return (
    <div className="text-center fw-bold w-100 p-3 bg-danger-subtle">
      {t("auction")} {t("ended_on")} {momentWithTZ(data).format("MMMM Do YYYY")}
    </div>
  );
};
