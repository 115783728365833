import React, { useState, useEffect, useRef, useContext } from "react";
import CustomNavbar from "../components/navBar/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Topbar from "../components/Top Bar/Topbar";
import PageLoader from "../components/PageLoader";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";

import { Row, Col } from "react-bootstrap";
import CountdownTimer2 from "../components/CountdownTimer/CountdownTimer2";
import { MdOutlineFeedback, MdOutlineInfo } from "react-icons/md";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaRegFilePdf } from "react-icons/fa6";
import BackButton from "../components/BackButton";
import { GrGallery } from "react-icons/gr";
import Carousel2 from "../components/Carousel/Carousel2";
import {
  calculatePercentage,
  formatNumber,
  isAuthenticated,
  isCurrentTimeInRange,
  isPastDateTime,
  momentWithTZ,
  setRedirectionLink,
} from "../utils/helper";
import PaymentModal from "../components/PaymentModal";
import { TiTick } from "react-icons/ti";
import Footer from "../components/Footer/Footer";
import FeedbackModal from "../components/auction/FeedbackModal";
import BreadCrumb from "../components/BreadCrumb";
import { PiHandWithdrawBold } from "react-icons/pi";
import { Button } from "../components/Buttons";
import { ChatBox } from "../components/Chatbox";
import QueryModal from "../components/auction/QueryModal";
import { ContentBlock, ListingCard } from "../components/Cards";
import DocsUpload from "../components/auction/DocsUpload";
import clsx from "clsx";
import useForceUpdate from "../hooks";
import { AppContext } from "../components/Contexts/AppContext";

const AssetDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [assetData, setAssetData] = useState({});
  const ifPaymentDone = assetData?.payment?.pay_status === "Paid";
  const [bidsList, setBidsList] = useState([]);

  const [isPageLoader, setPageLoader] = useState(false);
  const isAuctionEnded = isPastDateTime(
    assetData.auctions?.end_date,
    assetData.auctions?.end_time
  );
  const isAuctionStarted = isPastDateTime(
    assetData.auctions?.start_date,
    assetData.auctions?.start_time
  );
  const { auctionId, id } = useParams();

  const [bidValue, setBidValue] = useState(0);
  const [biddingLoader, setBiddingLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [feedbackShow, setFeedbackShow] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [showDocUpload, setShowDocUpload] = useState(false);
  const infath_id = localStorage.getItem("infath_id");
  const forceUpdate = useForceUpdate();
  const { fcmData } = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAssetDetails(true);
    getBidsData();
  }, []);

  useEffect(() => {
    if (fcmData?.data?.type === "auction") {
      getAssetDetails();
      getBidsData();
    }
  }, [fcmData]);

  const getAssetDetails = async (loader = false) => {
    loader && setPageLoader(true);
    try {
      const response = await ApiService.request({
        method: "GET",
        url: `get-auction-asset-details/${id}`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setAssetData(data.data);
        if (data.data?.payment?.pay_status === "Paid") {
          setBidValue(+data.data.highestBid);
          if (
            isAuthenticated() &&
            infath_id &&
            !ifAuctionSoldOrCancelled &&
            (data.data.bid_profile_status === null ||
              data.data.bid_profile_status === "rejected")
          ) {
            setShowDocUpload(true);
          }
        } else {
          setBidValue(+data.data.auctions.deposit_starts_from);
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setPageLoader(false);
    }
  };

  const getBidsData = async (loader = false) => {
    loader && setPageLoader(true);
    try {
      const response = await ApiService.request({
        method: "GET",
        url: `get-auction-bids/${id}`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setBidsList(data.data.bids || []);
      }
    } catch (error) {
    } finally {
      setPageLoader(false);
    }
  };

  const handleDeposit = () => {
    if (sessionStorage.getItem("captcha_verification")) {
      initiatePayment();
    } else {
      setRedirectionLink(window.location.href);
      navigate("/nafath-auth");
    }
  };

  const registrationHandler = () => {
    setRedirectionLink(window.location.href);
    navigate("/login");
  };

  const handleValueChange = (action) => {
    if (action === "increment") {
      setBidValue((ps) => ps + +assetData.auctions?.increment_value);
    }
    if (action === "decrement") {
      setBidValue((ps) => ps - +assetData.auctions?.increment_value);
    }
  };

  const bidNow = async () => {
    if (bidValue <= 0) {
      toast.error("Invalid bidding value");
      return;
    }

    if (
      assetData.bid_profile_status === null ||
      assetData.bid_profile_status === "rejected"
    ) {
      toast.error("Please Upload the documents first", {
        position: "top-center",
      });
      setShowDocUpload(true);
      return;
    }

    setBiddingLoader(true);
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `bidAuction`, // Replace with your API endpoint
        data: { auction_id: id, amount: bidValue },
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        getAssetDetails();
        getBidsData();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setBiddingLoader(false);
    }
  };

  const initiatePayment = async () => {
    setBiddingLoader(true);
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `initiatePayment`, // Replace with your API endpoint
        data: { amount: +assetData?.auctions?.deposit_starts_from },
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        setPaymentMethods(data.data.paymentMethods);
        setShow(true);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setBiddingLoader(false);
    }
  };

  const exceutePayment = async (apiData, setLoading) => {
    try {
      const apiPayload = {
        auction_id: id,
        amount: bidValue,
        paymentMethodId: apiData.paymentMethodId,
        customerName: apiData.customerName,
        customerEmail: apiData.customerEmail,
        redirect_url: window.location.href,
      };

      const response = await ApiService.request({
        method: "POST",
        url: `executePayment`, // Replace with your API endpoint
        data: apiPayload,
      });

      const data = response.data;

      if (data.status) {
        toast.success(data.message);
        window.open(data.data.payment_url, "_self");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const commissionValue = calculatePercentage(
    +assetData.auctions?.commission,
    bidValue
  );

  const shareItHandler = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success(t("link_copy"));
  };

  const showFeedbackButton = assetData.myBid && isAuctionEnded;

  const sendFeedback = async (apiData, next) => {
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `submit-auctions-feedback`, // Replace with your API endpoint
        data: { auction_id: assetData.auctions.id, ...apiData },
      });

      const data = response.data;

      if (data.status) {
        toast.success(data.message);
        getAssetDetails();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      next();
    }
  };

  const handleFeedback = async () => {
    setFeedbackShow(true);
  };

  const handleWithdraw = async () => {
    setWithdrawLoading(true);
    try {
      const response = await ApiService.request({
        method: "GET",
        url: `send-withdrawal-request/` + id, // Replace with your API endpoint
      });

      const data = response.data;

      if (data.status) {
        toast.success(data.message);
        getAssetDetails();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setWithdrawLoading(false);
    }
  };

  const ifAuctionCancelled = (data = assetData) =>
    data?.auctions?.sell_and_cancel_request?.request_type === "cancel" &&
    data?.auctions?.sell_and_cancel_request?.status === "approved";

  const ifAuctionSold = (data = assetData) =>
    data?.auctions?.sell_and_cancel_request?.request_type === "sell" &&
    data?.auctions?.sell_and_cancel_request?.status === "approved";

  const ifAuctionSoldOrCancelled = ifAuctionSold() || ifAuctionCancelled();

  return (
    <div>
      <DocsUpload
        show={showDocUpload}
        setShow={setShowDocUpload}
        assetData={assetData}
        getAssetDetails={getAssetDetails}
      />
      <PaymentModal
        show={show}
        setShow={setShow}
        exceutePayment={exceutePayment}
        paymentMethods={paymentMethods}
      />
      <FeedbackModal
        show={feedbackShow}
        setShow={setFeedbackShow}
        sendFeedback={sendFeedback}
      />
      {isPageLoader ? (
        <PageLoader />
      ) : (
        <div>
          <Topbar />
          <CustomNavbar />
          <BreadCrumb
            items={[
              { label: "home", link: "/" },
              { label: "auctions", link: "/auctions" },
              {
                label: `${t("auction")} ${t("details")}`,
                link: `/auctions/${auctionId}`,
              },
              { label: `${t("asset")} ${t("details")}` },
            ]}
          />

          <div className="bg-white py-4 px-5">
            <h2>
              <BackButton /> {assetData?.auctions?.title}
            </h2>
            <Row className="w-100">
              <Col md={7}>
                <Carousel2
                  items={assetData?.auctions?.media_files || []}
                  disableThumbnails
                />

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <button
                      className="btn btn-base py-2 px-4"
                      onClick={() =>
                        window.open(assetData?.auctions?.brochure, "_blank")
                      }
                    >
                      {t("brochure")} <FaRegFilePdf />
                    </button>
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <button
                    className="btn btn-base py-2 px-4"
                    // onClick={() => setShow(true)}
                  >
                    {t("gallery")} <GrGallery />
                  </button>
                  <button
                    className="btn btn-light py-2 px-4"
                    onClick={shareItHandler}
                  >
                    {t("share_it")} <IoShareSocialOutline className="" />
                  </button>
                </div>

                <div className="table-responsive mt-4">
                  {!!bidsList?.length && (
                    <table className="table rounded-table">
                      <thead className="">
                        <tr className="">
                          <th scope="col" className="bg-tertiary">
                            {t("id")}
                          </th>
                          <th scope="col" className="bg-tertiary">
                            {t("bidder_name")}
                          </th>
                          <th scope="col" className="bg-tertiary">
                            {t("bid_price")}
                          </th>
                          <th scope="col" className="bg-tertiary">
                            {t("when")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {assetData?.bids?.map((item) => (
                          <tr key={item.id}>
                            <td>{item.user.id}</td>
                            <td>
                              {item.user.first_name + " " + item.user.last_name}
                            </td>
                            <td>
                              {t("sar")} {formatNumber(item.amount)}
                            </td>
                            <td>
                              {momentWithTZ(item.created_at)
                                .startOf("day")
                                .fromNow()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>

                <div className="row">
                  <ListContainer>
                    <ListingCard
                      heading={t("street_details")}
                      items={[
                        {
                          name: t("name"),
                          value: assetData?.auctions?.street_name,
                        },
                        {
                          name: t("street_width"),
                          value: assetData?.auctions?.street_width,
                        },
                        {
                          name: t("total"),
                          value: (
                            <span>
                              <b>{formatNumber(commissionValue + bidValue)}</b>{" "}
                              {t("sar")}
                            </span>
                          ),
                        },
                      ]}
                    />
                  </ListContainer>

                  <ListContainer>
                    <ListingCard
                      heading={t("additional_info")}
                      items={[
                        {
                          name: t("asset_type"),
                          value: t(assetData?.auctions?.asset_type),
                        },
                        {
                          name: t("bathroom"),
                          value: assetData?.auctions?.bathroom,
                        },
                        {
                          name: t("bedrooms"),
                          value: assetData?.auctions?.bedroom,
                        },
                        {
                          name: t("electricity"),
                          value: assetData?.auctions?.electricity
                            ? t("yes")
                            : t("no"),
                        },
                        {
                          name: t("water"),
                          value: assetData?.auctions?.water
                            ? t("yes")
                            : t("no"),
                        },
                      ]}
                    />
                  </ListContainer>

                  <ListContainer>
                    <ListingCard
                      heading={t("length_border")}
                      items={[
                        {
                          name: t("north"),
                          value: assetData?.auctions?.north,
                        },
                        {
                          name: t("south"),
                          value: assetData?.auctions?.south,
                        },
                        {
                          name: t("east"),
                          value: assetData?.auctions?.east,
                        },
                        {
                          name: t("west"),
                          value: assetData?.auctions?.west,
                        },
                      ]}
                    />
                  </ListContainer>
                </div>

                {/* map */}
                <ContentBlock
                  className={"mt-5"}
                  heading={t("location_details")}
                  content={
                    <iframe
                      src={`https://www.google.com/maps?q=${assetData?.auctions?.lat},${assetData?.auctions?.lng}&hl=es;z=14&output=embed`}
                      width="100%"
                      height="320px"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title={"asset"}
                    ></iframe>
                  }
                />

                <ContentBlock
                  className={"mt-5"}
                  heading={t("title_deed")}
                  content={assetData?.auctions?.title_deed}
                />
                <ContentBlock
                  className={"mt-5"}
                  heading={t("description")}
                  htmlContent={assetData?.auctions?.description}
                />
              </Col>
              <Col md={5} className="relative">
                <div className="asset-bid-card border rounded-3 shadow overflow-hidden">
                  {/* countdown timer */}
                  <div className="w-100">
                    <CountdownTimer2
                      className="rounded-remove"
                      data={assetData?.auctions}
                      theme="gamma"
                      showClockIcon
                      showCancelAuction={
                        <>
                          {ifAuctionSold() && (
                            <div className="w-100">
                              <div className="text-center fw-bold w-100 p-3 bg-success text-white">
                                {t("auction_sold")}
                              </div>
                            </div>
                          )}

                          {ifAuctionCancelled() && (
                            <div className="w-100">
                              <div className="text-center fw-bold w-100 p-3 bg-danger-subtle">
                                {t("auction_cancel")}
                              </div>
                            </div>
                          )}
                        </>
                      }
                      forceUpdate={forceUpdate}
                    />
                  </div>

                  {/* auction bidding and deposit details */}
                  <div className="d-flex justify-content-between bg-light-gamma py-2 px-3 w-100">
                    <div className="d-flex flex-column">
                      <span>{t("opening_price")}</span>
                      <span>
                        <b>{formatNumber(assetData?.auctions?.open_price)}</b>{" "}
                        {t("sar")}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span>{t("deposit_amount")}</span>
                      {assetData?.auctions?.deposit_starts_from && (
                        <span>
                          <b>
                            {formatNumber(
                              assetData?.auctions?.deposit_starts_from
                            )}
                          </b>{" "}
                          {t("sar")}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* highest bid */}
                  {assetData?.highestBid && (
                    <div className="d-flex p-3 justify-content-between mb-2">
                      <span>
                        {t("highest_bid")}{" "}
                        <i className="bi bi-graph-up-arrow"></i>
                      </span>
                      <span>
                        <b>{formatNumber(assetData?.highestBid)}</b> {t("sar")}
                      </span>
                    </div>
                  )}

                  {!isAuctionEnded && !ifAuctionSoldOrCancelled && (
                    <div className={`w-100 px-4 my-2`}>
                      {assetData?.auctions?.payment !== "Paid" && (
                        <div className="d-flex justify-content-between w-100">
                          <div>
                            <b>{t("make_the_initial_offer")}</b>{" "}
                            <MdOutlineInfo size={20} />
                          </div>
                          <span>
                            {t("increase")}{" "}
                            <b>{assetData.auctions?.increment_value}</b>{" "}
                            {t("sar")}
                          </span>
                        </div>
                      )}

                      {/* bid counter */}
                      {assetData?.payment?.pay_status === "Paid" && (
                        <div
                          className={clsx(
                            "mt-3 rounded-3 w-100 row g-0 w-100 border border-light-alpha"
                          )}
                        >
                          <span
                            className={`col-2 text-center p-3 light-alpha cursor-pointer ${
                              assetData.highestBid >= bidValue ? "disabled" : ""
                            }`}
                            onClick={() => handleValueChange("decrement")}
                          >
                            <FaMinus className="light-alpha" size={22} />
                          </span>
                          <span className="col-8 border-start border-end border-light-alpha text-center p-3">
                            <b>{formatNumber(bidValue)}</b>
                          </span>
                          <span
                            className="col-2 text-center p-3 light-alpha cursor-pointer"
                            onClick={() => handleValueChange("increment")}
                          >
                            <FaPlus className="light-alpha" size={22} />
                          </span>
                        </div>
                      )}

                      {isAuthenticated() ? (
                        ifPaymentDone ? (
                          <BidButton
                            onClick={bidNow}
                            assetData={assetData}
                            biddingLoader={biddingLoader}
                            showDocUpload={showDocUpload}
                            setShowDocUpload={setShowDocUpload}
                            getAssetDetails={getAssetDetails}
                          />
                        ) : (
                          <DepositButton
                            onClick={handleDeposit}
                            assetData={assetData}
                            biddingLoader={biddingLoader}
                          />
                        )
                      ) : (
                        <RegistrationButton
                          onClick={registrationHandler}
                          assetData={assetData}
                        />
                      )}
                    </div>
                  )}

                  {/* registered  */}
                  {!isAuctionEnded &&
                    assetData?.payment?.pay_status === "Paid" && <Registered />}

                  {isAuctionStarted && (
                    <PriceBreakDown
                      assetData={assetData}
                      commissionValue={commissionValue}
                      bidValue={bidValue}
                    />
                  )}

                  {((assetData?.payment?.pay_status === "Paid" &&
                    !assetData?.is_withdrawal) ||
                    showFeedbackButton) && (
                    <div className="d-flex gap-3 my-3 justify-content-center">
                      {showFeedbackButton && (
                        <Button className="px-4" onClick={handleFeedback}>
                          {t("share_feedback")}{" "}
                          <MdOutlineFeedback fontSize={24} />
                        </Button>
                      )}
                      {assetData?.payment?.pay_status === "Paid" &&
                        !assetData?.is_withdrawal && (
                          <Button
                            className="px-4"
                            onClick={handleWithdraw}
                            loading={withdrawLoading}
                          >
                            {t("withdraw")} <PiHandWithdrawBold fontSize={24} />
                          </Button>
                        )}
                    </div>
                  )}

                  <div className="my-3 text-center">
                    {isAuthenticated() && <HavingSomeIssues />}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default AssetDetails;

const Registered = () => {
  const { t } = useTranslation();
  return (
    <div className="w-100 text-center my-2 bg-tertiary p-2">
      <b>{t("registered")}</b> <TiTick color="green" size={24} />
    </div>
  );
};

const PriceBreakDown = ({ assetData, commissionValue, bidValue }) => {
  const { t } = useTranslation();
  return (
    <div className="w-100 px-4 fs-5 my-3">
      <ListingCard
        items={[
          {
            name: t("price_per_square_meter"),
            value: assetData.auctions?.price_per_sqm,
          },
          {
            name: (
              <>
                {t("commission")} {assetData.auctions?.commission}%
              </>
            ),
            value: (
              <span>
                <b>{formatNumber(commissionValue)}</b> {t("sar")}
              </span>
            ),
          },
          {
            name: t("total"),
            value: (
              <span>
                <b>{formatNumber(commissionValue + bidValue)}</b> {t("sar")}
              </span>
            ),
          },
        ]}
      />
    </div>
  );
};

const HavingSomeIssues = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showQueryModal, setQueryModal] = useState(false);
  const [chatData, setChatData] = useState([]);
  const ticketId = useRef();

  const getChat = async () => {
    if (!isAuthenticated()) return;
    try {
      const response = await ApiService.request({
        method: "GET",
        url: `ticket-details/${id}`, // Replace with your API endpoint
      });

      const data = response.data;

      if (data.status) {
        if (data.data.id) {
          ticketId.current = data.data.id;
          const result = data.data;
          setChatData(result);
        }
      }
    } catch (error) {
    } finally {
      // next();
    }
  };

  const sendMessage = async (apiData, next) => {
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `submit-ticket`, // Replace with your API endpoint
        data: apiData,
      });

      const data = response.data;

      if (data.status) {
        toast.success(data.message);
        getChat();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      next();
    }
  };

  useEffect(() => {
    getChat();
  }, []);

  return (
    <>
      <span
        className="pt-1 cursor-pointer"
        onClick={() => (ticketId.current ? setShow(true) : setQueryModal(true))}
      >
        {t("having_some_issues")} <i className="bi bi-chat"></i>
      </span>
      {show && (
        <ChatBox
          chatData={chatData}
          setChatData={setChatData}
          onClose={() => setShow(false)}
          ticketId={ticketId.current}
          getChat={getChat}
        />
      )}
      {showQueryModal && (
        <QueryModal
          show={showQueryModal}
          setShow={setQueryModal}
          sendMessage={sendMessage}
        />
      )}
    </>
  );
};

const ListContainer = ({ children }) => {
  return <div className="col-7 mt-5 px-3">{children}</div>;
};

const DepositButton = ({ onClick, biddingLoader, assetData }) => {
  const { t } = useTranslation();
  
  return (
    <Button
      className="mt-2 btn btn-base py-3 text-white w-100"
      onClick={onClick}
      loading={biddingLoader}
    >
      {t("deposit")}
    </Button>
  );
};

const BidButton = ({
  onClick,
  biddingLoader,
  assetData,
  showDocUpload,
  setShowDocUpload,
  getAssetDetails,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        className="mt-2 btn btn-base py-3 text-white w-100"
        onClick={onClick}
        disabled={
          biddingLoader ||
          !assetData.alreadyBid ||
          assetData.bid_profile_status !== "approved"
        }
        loading={biddingLoader}
      >
        {t("bid_now")}
      </Button>
      <DocsUpload
        show={showDocUpload}
        setShow={setShowDocUpload}
        assetData={assetData}
        getAssetDetails={getAssetDetails}
      />
    </>
  );
};
const RegistrationButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      className="mt-2 btn btn-base py-3 text-white w-100"
      onClick={onClick}
    >
      {t("registration")}
    </Button>
  );
};
