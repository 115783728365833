import React, { useState, useEffect } from "react";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Topbar from "../components/Top Bar/Topbar";
import PageLoader from "../components/PageLoader";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";
import { FaRegFilePdf } from "react-icons/fa6";
import { GrGallery } from "react-icons/gr";
import { Row, Col } from "react-bootstrap";
import CountdownTimer2 from "../components/CountdownTimer/CountdownTimer2";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import Carousel2 from "../components/Carousel/Carousel2";
import BackButton from "../components/BackButton";
import { checkAuctionStatus, momentWithTZ } from "../utils/helper";
import logoInfath from "../assets/img/logo-infath.jpeg";
import BreadCrumb from "../components/BreadCrumb";
import { ContentBlock } from "../components/Cards";
import { YoutubeVideo } from "../components/Video";
import { CiYoutube } from "react-icons/ci";

const AuctionDetails = () => {
  const { t } = useTranslation();
  const [mediaType, setMediaType] = useState("video");

  const [isPageLoader, setPageLoader] = useState(false);
  const [auction, setAuction] = useState({});
  const [assets, setAssets] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const auctionInfo = checkAuctionStatus(assets.data);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAuctionDetails();
  }, []);

  const getAuctionDetails = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: "GET",
        url: `get-auction-assets/${id}`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setAuction(data.auction);
        setAssets(data);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const getPhoneNumber = () => {
    return `tel:+${auction?.sales_agent?.phone_number}`;
  };

  const getWhatsappUrl = () => {
    return `https://wa.me/${
      auction?.sales_agent?.whatsapp_number
    }?text=${encodeURIComponent("Hi!")}  `;
  };

  const ifAuctionCancelled = (data) =>
    data?.sell_and_cancel_request?.request_type === "cancel" &&
    data?.sell_and_cancel_request?.status === "approved";

  const ifAuctionSold = (data) =>
    data?.sell_and_cancel_request?.request_type === "sell" &&
    data?.sell_and_cancel_request?.status === "approved";

  return (
    <div className="">
      {isPageLoader ? (
        <PageLoader />
      ) : (
        <div>
          <div>
            <Topbar />
            <CustomNavbar />

            <BreadCrumb
              items={[
                { label: "home", link: "/" },
                { label: "auctions", link: "/auctions" },
                { label: "details" },
              ]}
            />

            <div className="bg-white py-4 px-5">
              <Row className="w-100">
                <h3>
                  <BackButton /> {auction.group_name}
                </h3>
                <Col md={7}>
                  {mediaType === "photos" && (
                    <Carousel2
                      items={auction?.[mediaType] || []}
                      disableThumbnails
                    />
                  )}

                  {mediaType === "video" && (
                    <YoutubeVideo url={auction?.[mediaType]} />
                  )}

                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex gap-3">
                      <button
                        className="btn btn-base py-2 px-4"
                        onClick={() => window.open(auction.brochure, "_blank")}
                      >
                        {t("brochure")} <FaRegFilePdf />
                      </button>

                      <button
                        className="btn btn-base py-2 px-4"
                        onClick={() => {
                          setMediaType((ps) =>
                            ps === "photos" ? "video" : "photos"
                          );
                        }}
                      >
                        {mediaType === "photos" && (
                          <>
                            {t("video")} <CiYoutube size={22} />
                          </>
                        )}
                        {mediaType === "video" && (
                          <>
                            {t("gallery")} <GrGallery />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="rounded-3 shadow overflow-hidden">
                    <div className="d-flex flex-column align-items-center">
                      <div className="w-100 text-center fw-bold">
                        <CountdownTimer2
                          data={auctionInfo}
                          theme={"gamma"}
                          showClockIcon
                        />
                      </div>
                      <div className="w-100 d-flex justify-content-center my-3">
                        <div className="w-75">
                          <div className="p-3 w-100 bg-light-gamma border rounded-3">
                            <div className="d-flex mb-3">
                              <span className="border px-1 rounded-3">
                                <b>{t("selling_agent")}</b>-{" "}
                                {auction?.sales_agent?.name}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between gap-3">
                              <a
                                href={getPhoneNumber()}
                                className="d-flex justify-content-center align-items-center gap-2 btn-base w-100 py-2 px-3 rounded-3 no-underline"
                                title={auction?.sales_agent?.phone_number}
                              >
                                {t("connection")}
                                <FaPhoneAlt className="text-base" />
                              </a>
                              <a
                                className="d-flex justify-content-center align-items-center gap-2 w-100 py-2 px-3 rounded-3 no-underline"
                                href={getWhatsappUrl()}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  color: "#fff",
                                  backgroundColor: "#05973c",
                                }}
                              >
                                {t("whatsapp")}
                                <FaWhatsapp color="#fff" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center gap-4 mt-2 mb-4">
                        {auction?.auction_type === "infath" && (
                          <>
                            <img
                              src={logoInfath}
                              alt="Product"
                              className="auction-img"
                            />
                            <div className="vr"></div>
                          </>
                        )}
                        <img
                          src={auction?.group_icon}
                          alt="Product"
                          className="auction-img"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="table-responsive mt-5">
                <div className="mb-2">
                  {t("all_assets")} ({assets?.data?.length})
                </div>
                <table className="table rounded-table">
                  <thead className="bg-tertiary">
                    <tr className="bg-tertiary">
                      <th className="bg-tertiary text-start" scope="col">
                        {t("logo")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("title")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("asset_type")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("location")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("deposit")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("bids")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("status")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assets.data?.map((asset) => (
                      <tr key={asset.id}>
                        <td className="align-middle text-start">
                          <img
                            src={asset?.media_files?.[0]?.file}
                            alt="Product"
                          />
                        </td>
                        <td className="align-middle text-start">
                          {asset.title}
                        </td>
                        <td className="align-middle text-start">
                          {asset.asset_type}
                        </td>
                        <td className="align-middle text-start text-truncate">
                          {asset.location}
                        </td>
                        <td className="align-middle text-start">
                          {asset.deposit_starts_from}
                        </td>
                        <td className="align-middle text-start">
                          {asset.bids_count}
                        </td>
                        <td className="align-middle text-start">
                          <>
                            {ifAuctionCancelled(asset) ? (
                              <span className="badge text-bg-danger">
                                {t("cancelled")}
                              </span>
                            ) : ifAuctionSold(asset) ? (
                              <span className="badge text-bg-success">
                                {t("sold")}
                              </span>
                            ) : (
                              <CountdownTimer2
                                data={asset}
                                theme={"beta"}
                                showClockIcon
                                auctionEndUI={
                                  <span className="bg-danger-subtle p-2 rounded-3">
                                    {t("auction_ended")}
                                  </span>
                                }
                              />
                            )}
                          </>
                        </td>
                        <td className="align-middle text-start">
                          <button
                            className="btn btn-base"
                            onClick={() => {
                              navigate(`${asset.id}`);
                            }}
                          >
                            {t("details")}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-4">
                <div className="w-50">
                  <ContentBlock
                    className={""}
                    heading={t("title_deed")}
                    htmlContent={auction.description}
                  />
                </div>
                <div className="fs-5 mt-3">
                  {t("added")}: {momentWithTZ(auction.created_at).fromNow()} |{" "}
                  {t("group_reference_number")}: 543
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default AuctionDetails;
