import React from 'react';
import google from "../../assets/img/google.png";
import apple from "../../assets/img/apple.svg";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/img/footer-logo.png";
import footerimg from "../../assets/img/footer-img-2.png";
import footerFall from "../../assets/img/aqare-fal.png";
import pdfLink from "../../assets/licence-Aqare.pdf";
import { useTranslation } from "react-i18next";

import "./footer.css";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
    <div className="container-fluid footer-main">
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <div className="footer-img">
              <img src={footerLogo} alt="" />
            </div>
            <div className="footer-para">
              <p>{t('footer_para')}</p>
            </div>
            <div className="footer-social-icons">
              <div className="footer-icons text-white">
                <a  href="https://x.com/AqareAl42297" target='_blank'> <i className="bi bi-twitter-x"></i> </a>
                <a  href="https://www.facebook.com/profile.php?id=61567935840553" target='_blank'><i className="bi bi-facebook"></i></a> 
                <a  href="https://www.instagram.com/aqare.saa/" target='_blank'><i className="bi bi-instagram"></i></a> 
              </div>
            </div>

            <div className="footer-img">
              <img src={footerimg} alt="" />
            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <div className="footer-2nd-col">
              <div className="footer-heading-2 text-white">
                <h3>{t('quick_links')}</h3>
              </div>
              <ul className="footer-list">

                <Link to={"/about-us"}>{t('about_us')}</Link>
                <Link to={"/terms-and-conditions"}>{t('terms')}</Link>
                <Link to={"/rights-policy"}>{t('policy')}</Link>
                <Link to={"/privacy-policy"}>{t('p_policy')}</Link>
                <Link to={"/contact-us"}>{t('contact')}</Link>

              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="footer-3rd-col">
              <div className="footer-heading-2 text-white">
                <h3>{t('newsletter')}</h3>
              </div>
              <div className="footer-input">
                <input type="text" placeholder={t('enter_email')} />
              </div>
              <div className="footer-btn">
                <button>{t('subscribe')}</button>
              </div>
              <div className="footer-p">
                <p>{t('opportunity')}</p>
              </div>
              <div className="footer-img">
                <a href={"https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Bmlp/08daf892-186b-4589-8760-118f6f5ec215"} target='_blank' rel="noreferrer">
                  <img src={footerFall} alt="fall-img" className='w-50' />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="footer-4th-col">
              <div className="footer-heading-2 text-white">
                <h3>{t('contact')}</h3>
              </div>
              <ul className="footer-list footer-list-2">
                <Link to={"/"}>
                  <i className="bi bi-geo-alt-fill"></i> {t('topbar')}
                </Link>
                <Link to={"mailto:info@aqare.sa"}>
                  <i className="bi bi-envelope"></i> info@aqare.sa
                </Link>
                <Link to={"tel: 920032028"} >
                  <i className="bi bi-telephone-fill"></i> 
                  {/* <div className="d-flex gap-2"> */}
              <p className="mx-2 ltr d-inline">920032028</p><p className="mx-2 ltr d-inline">|</p>
              <p className="mx-2 ltr d-inline">0539909998</p>
              {/* </div> */}
                </Link>
                <Link to={"https://wa.me/0533492225"} >
                  <i className="bi bi-whatsapp ml-2"></i> <p className='ltr d-inline'>0533492225</p> 
                </Link>
                
              </ul>

              <h4 className="text-white">{t('download_app')}</h4>
              <div className="download-app">
                <div className="google-img">
                <Link to={"https://play.google.com/store/apps/details?id=net.aqare.aqareapp&pcampaignid=web_share"}> <img src={google} alt="" /></Link>
                </div>
                <div className="apple-img">
                <Link to={"https://apps.apple.com/sa/app/aqare/id6741023384"}> <img src={apple} alt="" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="container-fluid footer-main">
    <div className="copyright">
      <p>{t('copyright')}</p>
    </div>
  </div>
  </>
  );
};

export default Footer;
