import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button } from "../Buttons";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";
import { setRedirectionLink } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DocsUpload({ show, setShow, assetData, getAssetDetails }) {
  const [saudiCitizenOption, setSaudiCitizenOption] = useState("");
  const [saudiResidentOption, setSaudiResidentOption] = useState("");
  const [activeKey, setActiveKey] = useState("");
  const [values, setValues] = useState({});
  const [selectedResident, setSelectedResident] = useState("regular");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const infathId = localStorage.getItem("infath_id");

  useEffect(() => {
    if (infathId) {
      infathId[0] == 1 && setActiveKey("saudi_citizen");
      infathId[0] == 2 && setActiveKey("saudi_resident");
    }
  }, []);

  useEffect(() => {
    setValues({});
  }, [activeKey, saudiCitizenOption, saudiResidentOption]);

  const citizenOptions = {
    for_a_company: (
      <>
        {[
          {
            label: t("commercial_registration"),
            value: "commercial_registration",
          },
          {
            label: t("memorandum_of_association"),
            value: "memorandum_of_association",
          },
          { label: t("poa_proof"), value: "poa_proof" },
          { label: t("pledge_letter"), value: "pledge_letter" },
        ].map((item) => (
          <FileInput
            key={item.value}
            label={item.label}
            name={item.value}
            setValues={setValues}
          />
        ))}
      </>
    ),
    for_someone_else: (
      <>
        <FileInput
          label={t("poa_proof")}
          name={"poa_proof"}
          setValues={setValues}
        />
      </>
    ),
    as_an_establishment: (
      <>
        {[
          {
            label: t("commercial_registration"),
            value: "commercial_registration",
          },
          { label: t("poa_proof"), value: "poa_proof" },
          { label: t("pledge_letter"), value: "pledge_letter" },
        ].map((item) => (
          <FileInput
            key={item.value}
            label={item.label}
            name={item.value}
            setValues={setValues}
          />
        ))}
      </>
    ),
  };

  const saudiOptions = {
    for_self: (
      <>
        {[
          {
            label: "Ministry of Interior Approval",
            value: "ministry_of_interior_approval",
          },
        ].map((item) => (
          <FileInput
            key={item.value}
            label={item.label}
            name={item.value}
            values={values}
            setValues={setValues}
          />
        ))}
      </>
    ),
    for_a_company: (
      <>
        {[
          {
            label: "Commercial registration",
            value: "commercial_registration",
          },
          {
            label: "Memorandum of association",
            value: "memorandum_of_association",
          },
          { label: "POA proof", value: "poa_proof" },
          { label: "Pledge letter", value: "pledge_letter" },
        ].map((item) => (
          <FileInput
            key={item.value}
            label={item.label}
            name={item.value}
            values={values}
            setValues={setValues}
          />
        ))}
      </>
    ),
    as_an_establishment: (
      <>
        {[
          {
            label: "Commercial registration",
            value: "commercial_registration",
          },
          { label: "POA proof", value: "poa_proof" },
          { label: "Pledge letter", value: "pledge_letter" },
        ].map((item) => (
          <FileInput
            key={item.value}
            label={item.label}
            name={item.value}
            values={values}
            setValues={setValues}
          />
        ))}
      </>
    ),
    for_self_premium_resident: (
      <>
        {[
          {
            label: "IAM verification required",
            value: "iam_verification",
          },
          {
            label: "Ministry of Interior Approval",
            value: "ministry_of_interior_approval",
          },
        ].map((item) => (
          <FileInput
            key={item.value}
            label={item.label}
            name={item.value}
            values={values}
            setValues={setValues}
          />
        ))}
      </>
    ),
    for_self_regular_resident: (
      <>
        {[
          {
            label: "IAM verification required",
            value: "iam_verification",
          },
          {
            label: "Ministry of Interior Approval",
            value: "ministry_of_interior_approval",
          },
        ].map((item) => (
          <FileInput
            key={item.value}
            label={item.label}
            name={item.value}
            values={values}
            setValues={setValues}
          />
        ))}
      </>
    ),
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (!infathId) {
      setRedirectionLink(window.location.href);
      navigate("/nafath-auth");
      toast.info(t("verify_nafath"));
      return;
    }
    setLoading(true);
    formData.append("auction_id", assetData.auctions.id);
    formData.append("infath_id", infathId);
    formData.append("citizen_type", activeKey);
    formData.append(
      "document_type",
      activeKey === "saudi_citizen" ? saudiCitizenOption : saudiResidentOption
    );
    activeKey === "saudi_resident" &&
      formData.append("resident_type", selectedResident);
    Object.keys(values).forEach((key) => {
      formData.append(`pdf[${key}]`, values[key]);
    });

    try {
      const response = await ApiService.request({
        method: "POST",
        url: `create-auction-bidding-profiles`, // Replace with your API endpoint
        data: formData,
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        getAssetDetails();
        handleClose();
        localStorage.removeItem("infath_id");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("document_requirements")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {activeKey === "saudi_citizen" && (
            <>
              <h5>{t("saudi_citizen")}</h5>
              <Form onSubmit={handleSubmit}>
                {[
                  { label: t("for_self"), value: "for_self" },
                  { label: t("for_a_company"), value: "for_a_company" },
                  { label: t("for_someone_else"), value: "for_someone_else" },
                  {
                    label: t("as_an_establishment"),
                    value: "as_an_establishment",
                  },
                ].map((type) => (
                  <div key={type.value} className="mb-3">
                    <Form.Check
                      type="radio"
                      id={type.label}
                      label={type.label}
                      checked={saudiCitizenOption === type.value}
                      onChange={() => setSaudiCitizenOption(type.value)}
                    />
                  </div>
                ))}
                {citizenOptions[saudiCitizenOption] && (
                  <>
                    <h5 className="mt-5">{t("upload_files")}</h5>
                    <div>{citizenOptions[saudiCitizenOption]}</div>
                    <Button loading={loading}>{t("submit")}</Button>
                  </>
                )}
                {"for_self" === saudiCitizenOption && (
                  <Button loading={loading}>{t("submit")}</Button>
                )}
              </Form>
            </>
          )}

          {activeKey === "saudi_resident" && (
            <>
              <h5>{t("saudi_resident")}</h5>
              <Form onSubmit={handleSubmit}>
                <div className="btn-group mb-2" role="group">
                  <button
                    className={`btn ${
                      selectedResident === "regular"
                        ? "btn-secondary"
                        : "btn-outline-secondary"
                    }`}
                    onClick={() => setSelectedResident("regular")}
                    type="button"
                  >
                    {t("regular_resident")}
                  </button>
                  <button
                    className={`btn ${
                      selectedResident === "premium"
                        ? "btn-secondary"
                        : "btn-outline-secondary"
                    }`}
                    onClick={() => setSelectedResident("premium")}
                    type="button"
                  >
                    {t("premium_resident")}
                  </button>
                </div>

                {[
                  { label: t("for_self"), value: "for_self" },
                  { label: t("for_a_company"), value: "for_a_company" },
                  {
                    label: t("as_an_establishment"),
                    value: "as_an_establishment",
                  },
                ].map((type, index) => (
                  <div key={type.value} className="mb-3">
                    <Form.Check
                      type="radio"
                      id={type.label}
                      label={type.label}
                      checked={saudiResidentOption === type.value}
                      onChange={() => setSaudiResidentOption(type.value)}
                    />
                  </div>
                ))}
                {saudiOptions[saudiResidentOption] && (
                  <>
                    <h5 className="mt-5">{t("upload_files")}</h5>
                    <div>{saudiOptions[saudiResidentOption]}</div>
                    <Button loading={loading}>{t("submit")}</Button>
                  </>
                )}
              </Form>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default DocsUpload;

const FileInput = ({ label, name, setValues }) => {
  return (
    <Form.Group controlId={label} className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="file"
        onChange={(event) => {
          setValues((ps) => ({ ...ps, [name]: event.target.files[0] }));
        }}
        accept=".pdf"
        required
      />
    </Form.Group>
  );
};
