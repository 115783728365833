import { useEffect, useMemo, useState } from "react";
import { FaRegClock } from "react-icons/fa";
import {
  getDateMs,
  isCurrentTimeInRange,
  isFutureDateTime,
} from "../../utils/helper";
import { t } from "i18next";
import clsx from "clsx";
import { AuctionEnded } from "../auction";

const CountdownTimer2 = ({
  className = "",
  showClockIcon = false,
  data = {},
  theme = "beta",
  showAuctionEndUI = true,
  auctionEndUI,
  showCancelAuction,
  forceUpdate,
}) => {
  const [timeLeft, setTimeLeft] = useState();
  const [auctionStatus, setAuctionStatus] = useState(null);
  const [timeData, setTimeData] = useState(null);

  useEffect(() => {
    setTimeData(data);
  }, [data]);

  const areAllKeysZero = (obj) => {
    if (!obj) return false;
    return Object.values(obj).every((value) => value === 0);
  };

  // Function to calculate the time left based on the target timestamp
  const calculateTimeLeft = (targetTimestamp) => {
    const now = new Date().getTime();
    const timeDifference = targetTimestamp - now;

    if (timeDifference <= 0) return null;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };

  const targetTimestamp = useMemo(() => {
    let value = null;
    if (!timeData?.start_date) return value;

    if (isCurrentTimeInRange(timeData)) {
      value = getDateMs(timeData.end_date, timeData.end_time);
      setAuctionStatus("live");
    } else if (isFutureDateTime(timeData.start_date, timeData.start_time)) {
      value = getDateMs(timeData.start_date, timeData.start_time);
      setAuctionStatus("upcoming");
    } else {
      setAuctionStatus("ended");
    }
    const result = calculateTimeLeft(value);

    if (!areAllKeysZero(result)) {
      setTimeLeft(result);
    }
    return value;
  }, [timeData]);

  useEffect(() => {
    if (areAllKeysZero(timeLeft)) {
      setTimeout((_) => {
        setTimeData((ps) => ({ ...ps }));
        forceUpdate?.();
      }, 1000);
    }
  }, [timeLeft]);

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(targetTimestamp);
      setTimeLeft(newTimeLeft);
      if (areAllKeysZero(newTimeLeft)) clearInterval(timer); // Stop the timer when the countdown is complete
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, [targetTimestamp]);

  const getStatus = () => {
    if (auctionStatus === "live") return t("ends_in");
    else if (auctionStatus === "upcoming") return t("starts_in");
    return "";
  };

  if (showCancelAuction) {
    return showCancelAuction;
  }

  if (!isFutureDateTime(data.end_date, data.end_time))
    return showAuctionEndUI ? (
      <AuctionEnded data={data.end_date} ui={auctionEndUI} />
    ) : null;

  if (theme === "alpha")
    return (
      <div className="countdown-container border bg-light-subtle rounded-3">
        <div className="text=center">
          <b>{getStatus()}</b>
        </div>
        <div className="countdown-headings">
          <span>Days</span>
          <span>Hours</span>
          <span>Mins</span>
          <span>Sec</span>
        </div>
        <div className="countdown-values">
          <span>{timeLeft?.days}</span>
          <span>{timeLeft?.hours}</span>
          <span>{timeLeft?.minutes}</span>
          <span>{timeLeft?.seconds}</span>
        </div>
      </div>
    );

  if (theme === "beta")
    return (
      <div className={className}>
        <div className="d-flex align-items-end gap-1">
          <div
            className="d-flex flex-column align-items-center lh-1 gap-1"
            style={{ marginInlineEnd: "5px" }}
          >
            <span className="fs-8 fw-bolder">{getStatus()}</span>
            {showClockIcon && <FaRegClock className="" size={19.5} />}
          </div>

          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-8 light-alpha">Days</span>
            <span className="fs-5 fw-medium">{timeLeft?.days}</span>
          </div>
          <span className="pt-3">:</span>
          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-8 light-alpha">Hrs</span>
            <span className="fs-5 fw-medium">{timeLeft?.hours}</span>
          </div>
          <span className="pt-3">:</span>
          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-8 light-alpha">Mins</span>
            <span className="fs-5 fw-medium">{timeLeft?.minutes}</span>
          </div>
          <span className="pt-3">:</span>
          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-8 light-alpha">Secs</span>
            <span className="fs-5 fw-medium">{timeLeft?.seconds}</span>
          </div>
        </div>
      </div>
    );

  if (theme === "gamma")
    return (
      <div
        className={clsx(
          "rounded-3 text-center p-3 rounded-remove",
          className,
          isCurrentTimeInRange(data) ? "bg-tertiary" : "bg-color-2"
        )}
      >
        <div className="d-flex justify-content-center align-items-end gap-1">
          <div
            className="d-flex flex-column align-items-center lh-1 gap-1"
            style={{ marginInlineEnd: "5px" }}
          >
            <span className="fs-6 fw-bolder">{getStatus()}</span>
            {showClockIcon && <FaRegClock className="" size={19.5} />}
          </div>

          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-6 light-alpha">Days</span>
            <span className="fs-5 fw-medium">{timeLeft?.days}</span>
          </div>
          <span className="pt-3">:</span>
          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-6 light-alpha">Hrs</span>
            <span className="fs-5 fw-medium">{timeLeft?.hours}</span>
          </div>
          <span className="pt-3">:</span>
          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-6 light-alpha">Mins</span>
            <span className="fs-5 fw-medium">{timeLeft?.minutes}</span>
          </div>
          <span className="pt-3">:</span>
          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-6 light-alpha">Secs</span>
            <span className="fs-5 fw-medium">{timeLeft?.seconds}</span>
          </div>
        </div>
      </div>
    );
};

export default CountdownTimer2;
