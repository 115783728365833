import React, { useContext, useEffect } from "react";
import AppRouter from "./router/routes";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/css/base.css";
import "./i18n"; // Import i18n configuration
import { messaging } from "./utils/firebase";
import { onMessage } from "firebase/messaging";
import { AppContext } from "./components/Contexts/AppContext";

function App() {
  const { setFcmData } = useContext(AppContext);

  useEffect(() => {
    // ✅ Only run messaging logic on supported browsers
    if (
      typeof window !== "undefined" &&
      "Notification" in window &&
      "serviceWorker" in navigator &&
      messaging
    ) {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            onMessage(messaging, (payload) => {
              setFcmData(payload);
              toast.success(
                <div>
                  <strong>{payload.notification?.title}</strong>
                  <p>{payload.notification?.body}</p>
                </div>
              );
            });
          } else {
            console.warn("Notification permission not granted.");
          }
        })
        .catch((err) => {
          console.warn("Notification permission request failed:", err);
        });
    } else {
      console.warn("Firebase messaging is not supported on this device/browser.");
    }
  }, [setFcmData]);

  useEffect(() => {
    document.title = process.env.REACT_APP_NAME || "React App";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content =
        process.env.REACT_APP_META_DESCRIPTION || "React App Description";
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content =
        process.env.REACT_APP_META_KEYWORDS || "React App keywords";
    }

    const lang = localStorage.getItem("lang");
    document.documentElement.setAttribute("dir", lang === "ar" ? "rtl" : "ltr");
  }, []);

  return (
    <div>
      <ToastContainer />
      <AppRouter />
    </div>
  );
}

export default App;
