import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const QueryModal = ({ show, setShow, sendMessage }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const initialState = {
    auction_id: id,
    subject: "",
    priority: "low",
    related_to: "bid",
    description: "",
    // file: null,
  };

  const [formData, setFormData] = useState(initialState);
  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onCloseModal = () => {
    setLoading(false);
    setShow(false);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData();

    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    sendMessage?.(data, onCloseModal);
  };

  useEffect(() => {
    if (!show) {
      setFormData(initialState);
    }
  }, [show]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: file,
    }));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="px-4">
        <Modal.Title>{t("having_some_issues")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <form onSubmit={submitHandler}>
          <div>
            <label htmlFor="subject">{t("subject")} *</label>
            <input
              type="text"
              className="form-control"
              name="subject"
              onChange={handleChange}
              value={formData.subject}
              required
            />
          </div>

          <div className="my-3">
            <label htmlFor="name">{t("priority")} *</label>
            <select
              name="priority"
              className="form-select"
              onChange={handleChange}
              value={formData.priority}
              required
            >
              {[
                { label: "low", value: "low" },
                { label: "medium", value: "medium" },
                { label: "high", value: "high" },
              ].map((category, index) => (
                <option value={category.value} key={index}>
                  {t(category.label)}
                </option>
              ))}
            </select>
          </div>

          <div className="my-3">
            <label htmlFor="name">{t("related_to")} *</label>
            <select
              name="related_to"
              className="form-select"
              onChange={handleChange}
              value={formData.related_to}
              required
            >
              {[
                { label: "bid", value: "bid" },
                { label: "deposit", value: "deposit" },
                { label: "refund", value: "refund" },
              ].map((category, index) => (
                <option value={category.value} key={index}>
                  {t(category.label)}
                </option>
              ))}
            </select>
          </div>

          <div className="my-3">
            <label htmlFor="description">
              {t("description")} <span className="text-danger">*</span>
            </label>

            <textarea
              className="form-control"
              rows={5}
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </div>

          {/* <div className="mb-3">
            <label for="formFile">{t("file")}</label>
            <input
              id="logoInput"
              type="file"
              accept="image/*"
              name="image"
              className="form-control"
              onChange={(e) => handleImageUpload(e)}
            />
          </div> */}

          <div className="text-end mt-4">
            <button className="btn btn-base" disabled={loading}>
              {t("submit")}
              {loading && (
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default QueryModal;
