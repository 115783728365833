import moment from "moment-timezone";

export const DEFAULT_TIMEZONE = moment.tz.guess(); // Change this if needed

export const momentWithTZ = (date = moment()) =>
  moment.tz(date, DEFAULT_TIMEZONE);

export const isAuthenticated = () =>
  !!JSON.parse(localStorage.getItem("user"))?.auth_token;

export function formatPrice(number) {
  return new Intl.NumberFormat("en-US").format(number);
}

/**
 * Formats a number with commas as thousand separators and optional decimal places.
 * @param {number} amount - The number to format.
 * @param {number} [decimalPlaces=2] - The number of decimal places to include.
 * @returns {string} The formatted number as a string.
 */
export function formatNumber(amount = 0, decimalPlaces = 0) {
  if (!amount) return 0;
  amount = +amount;
  // Ensure the input is a number
  if (isNaN(amount)) {
    throw new Error("Invalid number");
  }

  // Format the number with fixed decimal places
  const formattedAmount = amount.toFixed(decimalPlaces);

  // Add commas as thousand separators
  return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getRedirectionLink = () => {
  let link = sessionStorage.getItem("redirect_link");
  return link;
};

export const setRedirectionLink = (data) => {
  sessionStorage.setItem("redirect_link", data);
};

export function debounce(func, delay = 400) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const getDateMs = (dateValue, timeValue = "00:00:00") => {
  const dateTimeString = `${dateValue}T${timeValue}`; // Combine date and time
  const date = new Date(dateTimeString); // Convert to Date object
  return date.getTime(); // Return milliseconds
};

export const getDaysDifference = (startDate, endDate) => {
  // Define the two dates
  startDate = moment.tz(startDate, DEFAULT_TIMEZONE);
  endDate = moment.tz(endDate, DEFAULT_TIMEZONE);

  // Calculate the difference in days
  const differenceInDays = endDate.diff(startDate, "days");

  return differenceInDays || "";
};

export const getConvertedTime = (time) => {
  //convert time 24hrs format to 12hrs format, 18:31:00 --> 06:31 PM

  // Parse the time and format it to 12-hour format with AM/PM
  const formattedTime = moment
    .tz(time, "HH:mm:ss", DEFAULT_TIMEZONE)
    .format("hh:mm A");
  return formattedTime;
};

export const getDayName = (date) => {
  return moment.tz(date, DEFAULT_TIMEZONE).format("dddd");
};

export function isCurrentTimeInRange(data) {
  if (!data) return;
  // Get the current date and time
  const now = new Date();

  // Parse the start and end dates and times
  const startDateTime = new Date(`${data.start_date}T${data.start_time}`);
  const endDateTime = new Date(`${data.end_date}T${data.end_time}`);

  // Check if the current time is within the range
  return now >= startDateTime && now <= endDateTime;
}

export function calculatePercentage(percentage, total) {
  return (percentage / 100) * total;
}

export const isPastDateTime = (dateValue, timeValue = "00:00:00") => {
  const givenDateTime = moment.tz(
    `${dateValue} ${timeValue}`,
    "YYYY-MM-DD HH:mm:ss",
    DEFAULT_TIMEZONE
  );
  return givenDateTime.isBefore(moment.tz(DEFAULT_TIMEZONE)); // Check if it's after the current date-time
};

export const isFutureDateTime = (dateValue, timeValue = "00:00:00") => {
  const givenDateTime = moment.tz(
    `${dateValue} ${timeValue}`,
    "YYYY-MM-DD HH:mm:ss",
    DEFAULT_TIMEZONE
  );
  return givenDateTime.isAfter(moment.tz(DEFAULT_TIMEZONE)); // Check if it's after the current date-time
};

export function checkAuctionStatus(list = []) {
  let minStartDate = null,
    minStartTime = null;
  let maxEndDate = null,
    maxEndTime = null;
  let status = "ended";

  for (const { start_date, end_date, start_time, end_time } of list) {
    const startDate = moment.tz(start_date, "YYYY-MM-DD", DEFAULT_TIMEZONE);
    const startTime = moment.tz(start_time, "HH:mm:ss", DEFAULT_TIMEZONE);
    const endDate = moment.tz(end_date, "YYYY-MM-DD", DEFAULT_TIMEZONE);
    const endTime = moment.tz(end_time, "HH:mm:ss", DEFAULT_TIMEZONE);

    if (isCurrentTimeInRange({ start_date, end_date, start_time, end_time })) {
      return { start_date, start_time, end_date, end_time, status: "live" };
    }

    if (isFutureDateTime(start_date, start_time)) {
      status = "upcoming";
    }

    if (
      !minStartDate ||
      startDate.isBefore(minStartDate) ||
      (startDate.isSame(minStartDate) && startTime.isBefore(minStartTime))
    ) {
      minStartDate = startDate;
      minStartTime = startTime;
    }

    if (
      !maxEndDate ||
      endDate.isAfter(maxEndDate) ||
      (endDate.isSame(maxEndDate) && endTime.isAfter(maxEndTime))
    ) {
      maxEndDate = endDate;
      maxEndTime = endTime;
    }
  }

  return {
    start_date: minStartDate?.format("YYYY-MM-DD") || null,
    start_time: minStartTime?.format("HH:mm:ss") || null,
    end_date: maxEndDate?.format("YYYY-MM-DD") || null,
    end_time: maxEndTime?.format("HH:mm:ss") || null,
    status,
  };
}
