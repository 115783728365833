import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "../App.css";
import Navbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import "../components/header/header.css";
import Topbar from "../components/Top Bar/Topbar";
import "react-range-slider-input/dist/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";
import Paginator from "../components/Paginator";
import { RiErrorWarningLine } from "react-icons/ri";
import { PiCashRegister } from "react-icons/pi";
import { FaLocationDot } from "react-icons/fa6";
import CountdownTimer2 from "../components/CountdownTimer/CountdownTimer2";
import pluralize from "pluralize";
import faviIcon from "../assets/img/favicon.png";
import {
  checkAuctionStatus,
  debounce,
  formatNumber,
  getDateMs,
  getDaysDifference,
} from "../utils/helper";
import { LiveAuction } from "../components/auction";
import Dropdown from "../components/Dropdown";
import MenuModal from "../components/auction/MenuModal";
import NoDataFound from "../components/NoDataFound";
import momentHijri from "../utils/moment";
import BreadCrumb from "../components/BreadCrumb";
import useForceUpdate from "../hooks";

const Auctions = () => {
  const { t } = useTranslation();
  const [isPageLoader, setPageLoader] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [auctions, setAuctions] = useState([]);
  const [pagination, setPagination] = useState({});
  const app_lang = localStorage.getItem("lang") || "en";

  const filters = useRef({ page: 1 });

  useEffect(() => {
    window.scrollTo(0, 0);
    getAuctions(true);
  }, []);

  const getAuctions = async (loader = false) => {
    try {
      loader && setPageLoader(true);
      setCardLoading(true);
      const response = await ApiService.request({
        method: "GET",
        url: `get-auction-groups`, // Replace with your API endpoint
        params: filters.current,
      });
      const data = response.data;
      if (data.status) {
        setAuctions(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setPageLoader(false);
      setCardLoading(false);
    }
  };

  const handlePageChange = (page) => {
    filters.current.page = page;
    getAuctions();
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      value = value.target.value;
      filters.current.page = 1;
      if (!value) delete filters.current.search;
      else filters.current.search = value;
      getAuctions();
    }),
    []
  );

  const selectAuctionType = (e) => {
    const value = e.target.value;
    if (value === "auction_type") delete filters.current.auction_type;
    else filters.current.auction_type = value;

    getAuctions();
  };

  const selectAuctionStatus = (e) => {
    const value = e.target.value;
    if (value === "status") delete filters.current.status;
    else filters.current.status = value;

    getAuctions();
  };

  return (
    <div className="">
      {isPageLoader ? (
        <PageLoader />
      ) : (
        <div>
          {/* Top Bar */}
          <Topbar />

          {/* Navbar */}
          <Navbar />

          {/* Header */}
          <BreadCrumb
            items={[{ label: "home", link: "/" }, { label: "auctions" }]}
          />
          <div className="background-color mb-5">
            <div className="px-5 py-4 bg-white">
              <h3 className="fw-normal">{t("auctions")}</h3>
              <div className="mb-3 d-flex justify-content-between">
                <div className="d-flex gap-3">
                  <div className="input-group w-auto">
                    <span className="input-group-text">
                      <i className="bi bi-search"></i>
                    </span>
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      placeholder={t("auction_search")}
                      onChange={debouncedSearch}
                    />
                  </div>

                  <select
                    name="type"
                    className="form-select w-auto"
                    placeholder="Auction Type"
                    onChange={selectAuctionType}
                    defaultValue={"auction_type"}
                  >
                    <option value="auction_type">{t("auction_type")}</option>
                    <option value="infath">{t("infath")}</option>
                    <option value="private">{t("private")}</option>
                  </select>

                  <select
                    name="type"
                    className="form-select w-auto"
                    onChange={selectAuctionStatus}
                    defaultValue={"status"}
                  >
                    <option value="status">{t("status")}</option>
                    <option value="upcoming">{t("upcoming")}</option>
                    <option value="live">{t("live")}</option>
                    <option value="closed">{t("ended")}</option>
                  </select>
                </div>
                <div>
                  <Menu />
                </div>
              </div>
              <div className="row position-relative">
                {cardLoading && <CardLoader />}
                {auctions?.map((item) => (
                  <AuctionGroupCard data={item} key={item.id} />
                ))}
                {!auctions?.length && <NoDataFound />}
              </div>

              {pagination.total_pages > 1 && (
                <Paginator
                  page={pagination.current_page}
                  rows={pagination.per_page}
                  totalRecords={pagination.total_records}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Auctions;

const AuctionGroupCard = ({ data }) => {
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  
  const auctionInfo = checkAuctionStatus(data?.auctions);

  const getAuctionLabel = () => {
    const LabelContainer = ({ children, className = "" }) => (
      <span className={`px-2 rounded-5 fw-medium ${className}`}>
        {children}
      </span>
    );

    switch (auctionInfo.status) {
      case "upcoming":
        return (
          <LabelContainer className="bg-body-secondary">
            {t("upcoming")} <i className="bi bi-alarm"></i>
          </LabelContainer>
        );
      case "live":
        return (
          <LabelContainer className="bg-tertiary">
            <LiveAuction text={"live"} iconSize={"12px"} />
          </LabelContainer>
        );
      case "ended":
        return (
          <LabelContainer className="bg-danger-subtle">
            {t("ended")} <i className="bi bi-calendar-check"></i>
          </LabelContainer>
        );
      default:
        return "";
    }
  };

  const getAuctionStatus = () => {
    switch (auctionInfo.status) {
      case "upcoming":
        return (
          <>
            <RiErrorWarningLine size={22} />
            <span className="px-1">{t("auction_start")}</span>
          </>
        );
      case "live":
        return (
          <>
            <PiCashRegister size={22} />
            <span className="px-1">
              {t("reg_is")} <b>{t("open")}</b>
            </span>
          </>
        );
      case "ended":
        return (
          <>
            <RiErrorWarningLine size={22} />
            <span className="px-1">{t("auction_ended")}</span>
          </>
        );

      default:
        return "";
    }
  };

  const RoundedIcon = ({ icon }) => (
    <span className="py-1 px-2 align-items-center bg-light-alpha rounded-circle">
      {icon}
    </span>
  );

  return (
    <div className="col-md-6 col-xl-4 col-xxl-3 mb-3">
      <Link
        className="rounded-3 text-black text-decoration-none"
        to={`/auctions/${data.id}`}
      >
        <div className="position-relative">
          <div className="position-absolute top-0 left-0 d-flex gap-2 p-2">
            {getAuctionLabel()}
          </div>
          <img
            className="rounded-top-3 object-fit-cover"
            style={{ height: "15rem", width: "100%" }}
            alt="stock"
            src={data?.photos?.[0]?.file || faviIcon}
          />
        </div>
        <div className="border px-3 py-2">
          <div>
            <div className="mb-3">
              <h5 className="mb-1">{data.group_name}</h5>
              <div className="d-flex align-items-center gap-1">
                <FaLocationDot
                  color="var(--bs-primary)"
                  style={{ flexShrink: 0 }}
                />
                <span className="text-truncate">{data.location}</span>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-6">
                <div className="d-flex align-items-center gap-2">
                  <RoundedIcon icon={<i className="bi bi-house"></i>} />
                  <span>{pluralize(t("project"), data.auctions_count)}</span>
                  <b>{data.auctions_count}</b>
                </div>
              </div>
              {auctionInfo.status === "upcoming" && (
                <div className="col-6">
                  <div className="d-flex align-items-center gap-2">
                    <RoundedIcon icon={<i className="bi bi-alarm"></i>} />
                    <b>
                      {getDaysDifference(
                        auctionInfo.start_date,
                        auctionInfo.end_date
                      )}
                    </b>{" "}
                    {t("days")}
                  </div>
                </div>
              )}
            </div>

            <div className="row mb-3">
              <div className="d-flex align-items-center gap-2">
                <RoundedIcon icon={<i className="bi bi-calendar-range"></i>} />
                <div className="d-flex gap-3">
                  <div className="d-flex flex-column">
                    <span>{t("start")}</span>
                    <span>{t("end")}</span>
                  </div>
                  <div className="d-flex flex-column">
                    <b>{auctionInfo.start_date}</b>
                    <b>{auctionInfo.end_date}</b>
                  </div>
                  <div className="d-flex flex-column">
                    <b>
                      {getFormattedDT(
                        auctionInfo.start_date,
                        auctionInfo.start_time
                      )}
                    </b>
                    <b>
                      {getFormattedDT(
                        auctionInfo.end_date,
                        auctionInfo.end_time
                      )}
                    </b>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center lh-sm">
              <div className="d-flex flex-column">
                <span className="mb-1 light-alpha fs-7">
                  {t("deposit_starts_From")}
                </span>
                <span className="fw-bold">
                  {formatNumber(+data.deposit_starts_from)} {t("sar")}
                </span>
              </div>
              <CountdownTimer2
                data={auctionInfo}
                showClockIcon
                showAuctionEndUI={false}
                forceUpdate={forceUpdate}
              />
            </div>
          </div>
        </div>
        <div className="bg-base text-white px-3 py-2 rounded-bottom-3">
          <div className="d-flex gap-1 justify-content-center align-items-center">
            {getAuctionStatus()}
          </div>
        </div>
      </Link>
    </div>
  );
};

const CardLoader = () => {
  return (
    <div className="spinner-overlay">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const Menu = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Dropdown
        title={
          <>
            {t("menu")} <i className="bi bi-three-dots-vertical"></i>
          </>
        }
        variant="secondary"
        items={[
          { label: "contact_us", action: () => navigate("/contact-us") },
          { label: "how_it_works", action: () => setShow("HOW_IT_WORKS") },
          { label: "faqs", action: () => setShow("FAQS") },
          {
            label: "terms_and_conditions",
            action: () => navigate("/auctions-terms-conditions"),
          },
        ]}
      />
      <MenuModal type={show} show={!!show} setShow={setShow} />
    </>
  );
};

const getFormattedDT = (date, time) => {
  return momentHijri(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss").format(
    "iYYYY/iM/iD"
  );
};
