import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import "./user.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserSidebar = ({ setActiveComponent }) => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setActiveComponent(tab);
    if (window.innerWidth < 768) setIsOpen(false);
  };

  const logout = () => {
    const confirm = window.confirm("Are you sure? you want to logout?");
    if (confirm) {
      localStorage.removeItem("user");
      navigate("/");
    }
  };

  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <div>
      <button
        className="btn btn-primary d-md-none toggle-btn shadow"
        onClick={toggleSidebar}
      >
        <span className="account-menu">
          {t("account_menu")} <i className="bi bi-list fs-2"></i>
        </span>
      </button>

      <div
        className={`d-flex flex-column bg-white mt-5 user-side-bar ${
          isOpen ? "open" : ""
        }`}
      >
        <Nav className="flex-column">
          {[
            { name: "dashboard", icon: "bi-grid-3x2-gap", link: "/dashboard" },
            {
              name: "my_properties",
              icon: "bi bi-card-list",
              link: "/dashboard?tab=properties",
            },
            {
              name: "my-auctions",
              icon: "bi bi-houses",
              link: "/dashboard?tab=my-auctions",
            },
            {
              name: "tickets",
              icon: "bi bi-ticket",
              link: "/dashboard?tab=tickets",
            },
            {
              name: "agency",
              icon: "bi-house-door",
              link: "/dashboard?tab=agency",
            },
            {
              name: "favourites",
              icon: "bi-heart",
              link: "/dashboard?tab=favourites",
            },
            // { name: "chat", icon: "bi-chat-dots", link: "/dashboard?tab=chat" },
            {
              name: "payments",
              icon: "bi-wallet",
              link: "/dashboard?tab=payments",
            },
            {
              name: "account",
              icon: "bi-person",
              link: "/dashboard?tab=account",
            },
          ].map((tab, index) => (
            <Nav.Item
              key={index}
              className={`sideBar-item ${
                activeTab === tab.name ? "active-tab" : ""
              }`}
              onClick={() => handleTabClick(tab.name)}
            >
              <Link
                to={tab.link}
                className="d-flex align-items-center nav-link"
              >
                <i className={`bi ${tab.icon} mx-2`}></i>
                {t(tab.name)}
              </Link>
            </Nav.Item>
          ))}
          <Nav.Item className={`sideBar-item `} onClick={() => logout()}>
            <Nav.Link href="#" className="d-flex align-items-center ms-2">
              <i className={`bi bi-box-arrow-right me-2`}></i>
              {t("logout")}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </div>
  );
};

export default UserSidebar;
